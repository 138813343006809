.collab-nav-buttons{
    &__options{
        display: flex;
        flex:1 1;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 20px;
    }
    &__option{
        flex: 1 1;
        padding: 20px;
        @media screen and (max-width: 520px) {
            padding: 20px 10px;
        }
       
        
    }
    a {
        display: flex;
        flex-direction: row;
        @media screen and (max-width: 600px) {
            flex: 1 1;
        }
        &.active{
            @media screen and (max-width: 600px) {
                order:9999;
            }
         }
    }
    &__button {
        font-size: 24px;
        font-weight: 500;
        color: #fff;
        transition: 300ms ease all;
        min-width: 100px;
        @media screen and (max-width: 520px) {
            white-space: nowrap;
        }
        @media screen and (max-width: 600px) {
            text-align: center;
            font-size: 20px;
        }
        a {
            display: flex;
            flex-direction: row;
            
        }
        &:hover{
            filter: brightness(85%);
        }
        &.active{
            background: rgb(253,205,0);
            background: linear-gradient(90deg, rgba(253,205,0,1) 0%, rgba(226,150,1,1) 120%);
            text-align: center;
            border-radius: 29px;
            padding: 15px;
            font-weight: 600;
        }
    }
  
}