$cubic: cubic-bezier(0.64, 0.09, 0.08, 1);
.section-item {
  display: flex;
  width: auto;
  height: 6rem;
  padding: 20px 30px;
  max-width: 1300px;
  border: 0;
  border-radius: 1rem;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  text-decoration: none;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  color: #000000;
  background: #ececec;
  box-shadow: 1px 1px 9px -2px rgba(0, 0, 0, 0.53);
  @media screen and (max-width: 520px) {
    padding: 0 10px;
  }
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.22) 0px 19px 43px;
    transform: translate3d(0px, -1px, 0px);
  }

  &:disabled {
    color: #a8abad;
    background: transparent;
  }
  &.current {
    box-shadow: rgba(0, 0, 0, 0.22) 0px 19px 43px;
    transform: translate3d(0px, -1px, 0px) scale(1.04);
  }

  &.selected {
    color: #ffffff;
    background: #0072ff;
    background: linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr="$one", endcolorstr="$two", gradienttype=1);

    &:hover {
      color: #ececec;
      filter: brightness(1.1);
    }

    &:disabled {
      color: #a8abad;
      background: transparent;
    }
  }

  @media screen and (max-width: 680px) {
    border-radius: 9rem;
    font-size: 1rem;
    font-weight: 600;
  }

  &__text {
    width: 75%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 520px) {
      width: 70%;
    }
  }
  &__stats {
    display: flex;
    align-items: center;
    width: 20%;
    flex-wrap: wrap;
    color: #adadad;
    font-size: 20px;
    font-weight: 500;
    i {
      &.active {
        color: #00bcff;
      }
      font-size: 25px;
      padding: 0 10px;
    }
    @media screen and (max-width: 520px) {
      font-size: 13px;
      width: 25%;
      i {
        font-size: 15px;
        padding: 0 2px;
      }
    }
  }
  &__time {
    // padding:5px;
    width: 100%;
    span {
      vertical-align: top;
    }
  }
  &__chat,
  &__bookmark {
    // padding:5px;
    width: 49%;
    span {
      vertical-align: top;
    }
    &.bookmarks {
      font-size: 30px;
      width: 90%;
      margin-left: 30%;
      @media screen and (max-width: 680px) {
        margin-left: 0;
        font-size: 26px;
        width: 100%;
        text-align: center;
      }
    }
    &.notes {
      width: 100%;
      text-align: center;
      i {
        font-size: 36px;
      }
      @media screen and (max-width: 680px) {
        margin-left: 0;
        i {
          font-size: 26px;
        }
        padding-left: 0;
      }
    }
  }

  &__name {
    font-size: 22px;
    padding: 0 25px;
    color: #1c87e8;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 60%;
    @media screen and (max-width: 520px) {
      padding: 0 10px;
    }
  }
  &__description {
    font-size: 16px;
    padding: 0 25px;
    line-height: 14px;
    color: #757575;
    height: 40%;
    width: 100%;
    font-weight: 500;
    flex-wrap: wrap;
    white-space: normal;
    @media screen and (max-width: 520px) {
      padding: 0 10px;
    }
    &--small {
      display: none;
    }
  }

  &__checkmark {
    align-self: center;
    width: 5%;
    min-width: 50px;
    height: 70%;
    @media screen and (max-width: 520px) {
      // min-width: 20px;
    }
    .checkmark-check {
      stroke-dasharray: 80px 80px;
      stroke-dashoffset: -80px;
    }
  }

  &.completed {
    .checkmark-check {
      animation: 1s checkAnimate 1s forwards
        cubic-bezier(0.895, 0.03, 0.685, 0.22);
    }
  }
  &.inactive {
    cursor: not-allowed;
    filter: brightness(0.7);
  }

  @media screen and (max-width: 1100px) {
    min-height: 6rem;
    height: auto;
    border-radius: 10px;
    flex-wrap: wrap;
    &__checkmark {
    }
    &__text{
      flex-direction: row;
      flex:1;
      min-height: 100px;
    }
    &__name {
      width: 95%;
      white-space: initial;
      align-self: center;
      height: auto;
    }

    &__stats {
      width: 100%;
      flex-direction: row;
      flex: 1;
      justify-content: space-between;
      padding: 15px;
      font-size: 22px;
      i{
        font-size: 22px;
        padding-right: 10px;
      }
    }
    &__time,&__chat,&__bookmark{
      width: 33%;
      align-self: center;
      text-align: center;
    }

    &__description {
      display: none;
      &--small {
        display: inline-block;
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 18px;
      }
    }
  }
}

@keyframes checkAnimate {
  0% {
    stroke-dashoffset: -80px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}
