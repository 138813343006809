$cubic: cubic-bezier(.64,.09,.08,1);

.app-main.section .header{
  .navigation__icon-span{
    background: #fff;
}
}
.app-section{
  // background: #F5F5F5;
  .breadcrumb a{
    color: #fff;
  }
  
}
.section-page {
    display: flex;
    flex-direction: column;
    align-items: left;
    max-width: 1500px;
    margin: 0 auto;
    @media screen and (max-width: 640px) {
      padding: 20px 10px;
    }
    .breadcrumb{
      // width: 40%;
      max-width: 800px;
    }
    .title{
      font-weight: bold;
      font-size: 50px;
      margin-bottom: 40px;
      @media screen and (max-width: 520px) {
        font-size: 40px;
        margin-bottom: 0px;
      }
    }
    .sub-title{
      font-size: 20px;
      max-width: 600px;
      margin-bottom: 20px;
      @media screen and (max-width: 520px) {
        margin-bottom: 0px;
      }
    }
  &__container{
    margin: 20px;
  }
 

}
