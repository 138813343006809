.spk-chat {
  max-height: 100vh;
  height: 100vh;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &__main-panel {
    padding: 20px 10px 0 0px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  &__header {
    position: relative;
    z-index: 1;
    border-radius: 10px 10px 0 0;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: none;
    box-shadow: 0 7px 9px 0 rgba(0, 0, 0, 0.03), 0 1px 0 0 rgba(0, 0, 0, 0.03);
    padding: 10px 40px;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    font-size: 25px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 20px;
  }
  &__close-button {
    cursor: pointer;
  }
  &__input-flat {
    background: white;
    // padding: 10px 40px;
    position: relative;
    height: 150px;
    z-index: 1;
    width: 100%;
    border-top: 2px solid #e8e8e8;
  }
  &__input-flat-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  &__input-flat--textarea-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0;
    position: relative;
  }
  &__input-flat &__textarea {
    flex: 1;
  }

  &__input-flat &__textarea > textarea {
    min-height: 56px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 20px 20px;
    font-size: 15px;
    line-height: 17px;
    border: none;
    margin: 0;
  }
  &__textarea textarea {
    width: 100%;
    outline: none;
    padding: 11px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-size: 15px;
    min-height: 42px;
    transition: height 100ms ease-in;
    resize: none;
    font-family: inherit;
  }
  &__textarea-new{
    height: 150px;
    &-input{
      height: 100px;
    }
    &-controls{
      display: flex;
      height: 50px;
      flex-direction: row;
      align-content: center;
      justify-content: space-between;
      align-items: center;
      background: #e8e8e8;
    }
    &-control{
      cursor: pointer;
      margin: 0 20px;
      color: #a8a8a8;
      transition: all .3s cubic-bezier(0.4, 0, 0.2, 1);
      &:hover{
        color: darken(#a8a8a8,20%);
      }
      &--emoji{
        width: 35px;
        height: 35px;
      }
      svg{
        width: 100%;
        height: 100%;
      }
    }
    &-emoji-picker{
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 0;
    }
  }
  &__textarea-new textarea {
    width: 100%;
    outline: none;
    border: 0;
    padding: 11px;
    background: #fff;
    // border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0;
    font-size: 15px;
    min-height: 100px;
    transition: height 100ms ease-in;
    resize: none;
    overflow: scroll;
    font-family: inherit;
    transition: all .3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &__input-flat &__textarea > textarea:focus {
    border: none;
    border-radius: 8px;
    box-shadow: 0 0 0 3px #006cff;
  }
  &__textarea textarea:focus {
    background: #fff;
    border: 1px solid #006cff;
    box-shadow: 0 0 0 2px rgba(0, 108, 255, 0.36);
  }
  &__textarea-new textarea:focus {
    background: #fff;
    border: 0;
    border-top: 1px solid #006cff;
    box-shadow: 0 -2px 2px 1px rgba(0, 108, 255, 0.36);
  }

  @media screen and (max-width: 800px) {
    &__send-button {
      display: none;
    }
  }

  &__send-button {
    margin-left: 10px;
    border: 0;
    background: 0;
    outline: 0;
  }

  &__send-button-new {
    border-radius: 10px;
    background: darken(#3d97eb, 10%);
    color: #fff;
    outline: 0;
    border: 0;
    padding: 10px 20px;
    cursor: pointer;
    transition: all .7s cubic-bezier(0.4, 0, 0.2, 1);
    &:hover{
      background: darken(#3d97eb, 15%);
    }
    &:active{
      background: darken(#3d97eb, 20%);
    }
  }

  &__list {
    padding: 55px 40px 0;
    background: white;
  }
  &__list {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 75px 10px 0 10px;
  }

  &__ul {
    display: block;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__date-separator {
    display: flex;
    margin: 40px;
    align-items: center;
  }
  &__date-separator > *:not(:last-child) {
    margin-right: 20px;
  }
  &__date-separator-line {
    flex: 1;
    background-color: rgba(0, 0, 0, 0.1);
    height: 1px;
    border: none;
  }
  &__date-separator-date {
    font-size: 14px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.7);
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  &__li--single {
    margin-bottom: 20px;
  }
  &__li--top,
  &__li--single {
    margin: 20px 0 0;
  }
  &__li {
    display: block;
    position: relative;
  }

  &__message,
  &__message--me {
    margin: 1px 0;
  }
  &__message {
  }
  &__message-simple {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  &__message,
  &__message-simple {
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0 0 0 0;
    position: relative;
    margin: 1px 0;
  }

  &__avatar--circle {
    border-radius: 50%;
  }
  &__avatar {
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
    margin-right: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    overflow: hidden;
    img {
      width: 32px;
      height: 32px;
      flex-basis: 32px;
      object-fit: cover;
    }
  }

  &__message-inner,
  &__message-simple-inner {
    position: relative;
    @media screen and (max-width: 420px) {
      // margin-right: 90px;
      // margin-left: unset;
    }
  }

  &__message,
  &__message--me {
    margin: 1px 0;
  }
  &__li--top &__message--me,
  &__li--single &__message--me {
    text-align: right;
  }
  &__message {
    white-space: pre-wrap;
    .bb-code-text-block{
      display: inline;
    }
    &-video{
      display: block;
      margin: 5px 0;
      .simple-video-player__video{
        border: 0;
      }
    }
    &-image{
      display: block;
      margin: 5px 0;
      max-width: 100%;
    }
    &-link{
      display: inline;
      text-decoration: underline;
    }
  }
  &__message-simple {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  &__message,
  &__message-simple {
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0 0 0 0;
    position: relative;
    margin: 1px 0;
  }
  &__message--me,
  &__message-simple--me {
    display: flex;
    margin: 4px 0;
    justify-content: flex-end;
  }

  &__li--top &__message > &__avatar,
  &__li--middle &__message > &__avatar {
    visibility: hidden;
  }
  &__message--me > &__avatar {
    order: 1;
    margin: 0;
    margin-left: 10px;
  }

  &__message--me &__message-inner,
  &__message-simple--me &__message-inner {
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__message--me &__message-attachment-container,
  &__message-simple--me &__message-attachment-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__message-attachment-container,
  &__message-simple-attachment-container {
    display: flex;
    flex-direction: column;
  }

  &__message--bot &__message-text-inner,
  &__message-simple--bot &__message-text-inner {
    background: #d3eaff;
  }
  
  &__li--top &__message--me &__message-text-inner,
  &__li--bottom &__message--me &__message-text-inner {
    border-radius: 16px 16px 2px 16px;
  }
  &__li--middle &__message--me &__message-text-inner {
    border-radius: 16px 2px 2px 16px;
  }
  &__message--me &__message-text-inner,
  &__message-simple--me &__message-text-inner {
    flex: initial;
    background: #ebebeb;
    border-color: transparent;
    text-align: right;
    border-radius: 16px 16px 2px 16px;
    margin-right: 0;
  }
  &__li--top &__message-text-inner {
    border-radius: 16px 16px 16px 2px;
  }
  &__message-simple-text-inner {
    text-align: left;
    max-width: 560px;
  }
  &__message-text-inner,
  &__message-simple-text-inner {
    position: relative;
    flex: 1;
    display: block;
    min-height: 32px;
    padding: 5px 10px;
    font-size: 15px;
    color: black;
    border-radius: 16px 16px 16px 0;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    margin-left: 0;
  }

  &__message-simple--me &__message-simple__actions {
    order: -1;
  }
  &__message-simple--me &__message-simple__actions {
    justify-content: flex-end;
  }
  &__message-simple__actions {
    display: flex;
    margin-top: 5px;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 90px;
  }

  &__message--me &__message-data,
  &__message-simple--me &__message-data {
    text-align: right;
  }
  &__li--top &__message-data,
  &__li--middle &__message-data {
    display: none;
  }
  &__message-data,
  &__message-simple-data {
    margin-top: 5px;
    width: 100%;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.5);
  }

  &____message-name,
  &__message-simple-name {
    font-weight: 700;
    margin-right: 5px;
    &-coach{
      font-weight: 400;
      color: rgba(211, 169, 106, 0.9);
    }
  }

  &__message--me &__message-data,
  &__message-simple--me &__message-data {
    text-align: right;
  }
  &__li--top &__message-data,
  &__li--middle &__message-data {
    display: none;
  }
  &__message-data,
  &__message-simple-data {
    margin-top: 5px;
    width: 100%;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.5);
  }
  &__mention{
    cursor: pointer;
    background: #3cc4f1;
  }
  &__chat-button {
    text-decoration: underline;
    color: #31788e;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover{
      color: #265b6b;
    }
    &:active{
      color: #193a44;
    }
}
}

// width: 32px; height: 32px; flex-basis: 32px; object-fit: cover;

.mentions {
  // margin: 1em 0;
  height: 100%;
  textarea::placeholder {
    font-size: 16px;
  }

  &--singleLine &__control {
    display: inline-block;
    width: 130px;
  }
  &--singleLine &__highlighter {
    padding: 1px;
    border: 2px inset transparent;
  }
  &--singleLine &__input {
    padding: 1px;
    border: 2px inset;
  }

  &--multiLine &__control {
    font-family: monospace;
    font-size: 14pt;
  }
  &--multiLine &__highlighter {
    border: 1px solid transparent;
    padding: 9px;
    height: 100px;
  }
  &--multiLine &__input {
    // border: 1px solid silver;
    padding: 9px;
    outline: 0;
  }

  &__suggestions__list {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 10pt;
  }

  &__suggestions__item {
    padding: 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  &__suggestions__item--focused {
    background-color: #cee4e5;
  }

  &__mention {
    position: relative;
    z-index: 1;
    color: blue;
    text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
      -1px -1px 1px white;
    text-decoration: underline;
    pointer-events: none;
  }
  
}
.spk-chat__textarea-new-emoji-picker-picker--hide{
  visibility: hidden;
  pointer-events: none;
  max-height: 0;
}
