// .hide-header .header{
//   height: 0;
//   .header__small-screen-flex{
//     visibility: hidden;
//   }
// }

.header {
  height: 125px;
  width: 100%;
  display: flex;
  position: relative;
  z-index: 2;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 25px;
  background-color: rgba(48, 140, 228, 0);
  padding: 0 10px;
  box-shadow: 1px 1px 21px -2px rgba(0, 0, 0, 0.53);
  &__confetti{
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 999999999;
    transform: translate(-50%, -50%);
  }
  @media screen and (max-width: 680px) {
    padding: 0 15px;
  }
  &.inner{
    display: none;
  }

  @media screen and (max-width: 380px) {
    padding: 0;
  }

  .logo-container {
    cursor: pointer;
    // height: 50%;
    max-width: 900px;
    padding-right: 10px;

    img {
      height: 100%;
      width: 100%;
      // min-width: 350px;
      max-width: 450px;
    }
    @media screen and (max-width: 1420px) {
      // height: 55%;
      img {
        height: 100%;
      }
    }
    @media screen and (max-width: 1020px) {
      // height: 50%;
      img {
        height: 100%;
      }
    }
    @media screen and (max-width: 880px) {
      // height: 45%;
      img {
        height: 100%;
      }
    }
    
    @media screen and (max-width: 860px) {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      max-height: 70px;
      a{height: auto;}
      img {
        height: 100%;
        // width: 100%;
        min-width: auto;
      }
    }
    @media screen and (max-width: 660px) {
      img {
        // width: 68%;
        padding-top: 3px;
        max-height: 105px;
      }
    }
    @media screen and (max-width: 600px) {
      img {
        max-height: 70px;
      }
    }
    @media screen and (max-width: 460px) {
      img {
        // width: 100%;
      }
    }
  }
  
  &__logo{
   
    &--sm{
      display: none;
    }
    @media screen and (max-width: 860px) {
      display: none;
      &--sm{
        display:block;
        @media screen and (max-width: 600px) {
          // visibility: hidden;
            // max-height: 80px;
        }
      }
    }
  }
  &__small-screen-flex {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    z-index: 2;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 660px) {
      flex-direction: column;
    }
  }

  .options {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    // justify-content: flex-end;
    @media screen and (max-width: 660px) {
      width: 100%;
    }

    .option {
      // padding: 10px 15px;
      cursor: pointer;
      @media screen and (max-width: 660px) {
        padding: 0;
        width: 100%;
      }
    }
  }

  .user-name-greetings {
    display: flex;
    align-items: center;
    justify-content: center;
    &__help {
      background: #c2c2c2;
      color: #000;
      font-size: 25px;
      line-height: 29px;
      font-weight: 600;
      padding: 10px;
      margin-right: 10px;
      text-align: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      transition: 300ms ease all;
      &:hover {
        background: darken(#c2c2c2, 10%);
      }
      &:active {
        background: darken(#c2c2c2, 20%);
      }
    }
    .user-name-greetings-title {
      font-size: 24px;
      margin-right: 15px;
      @media screen and (max-width: 680px) {
        font-size: 16px;
      }
    }
    .custom-button {
      font-size: 12px;
    }
  }
  .form-select {
    position: relative;
    display: flex;
    height: 2em;
    min-width: 170px;
    line-height: 2;
    background: #2c3e50;
    overflow: hidden;
    border-radius: 0.25em;
    margin: 10px;
    border: 1px solid #c5c3c3;
    .form-input {
      margin: 0;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      outline: 0;
      box-shadow: none;
      border: 0 !important;
      background: #f5f8fa;
      background-image: none;
    }
    /* Remove IE arrow */
    select::-ms-expand {
      display: none;
    }
    /* Custom Select */

    select {
      flex: 1;
      padding: 0 0.7em;
      color: rgb(124, 124, 124);
      cursor: pointer;
      @media screen and (max-width: 480px){
        font-size: 16px;
        font-weight: 600;
      }
    }

    /* Transition */
    &:hover::after {
      color: #0487ff;
    }
  }

  .option-multiple-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .blacklisted-modal.access-code {
    max-width: 700px;
  }
  .blacklisted-modal__access_code_input {
    .group {
      padding: 0;
      margin: 0;
    }
    // margin-top: 50px;
    .form-input {
      background-color: #ececec;
      font-size: 26px;
      font-weight: 600;
    }
  }
}

.complete-item {
  &.modal-popup .modal-popup__container {
    transform-origin: 10%;
    background-color: #c9eafb;
    box-shadow: 0px 4px 16px 6px rgba(201, 234, 251, .3);
    border-radius: 20px;
    animation: bounceIn2 ease 1s;
    .modal-popup__close{
      display: none;
    }
  }
  .blacklisted-modal__title{
    margin-top: -12px;
  }
  
  &.modal-popup .modal-popup__container .modal-popup__body {
    padding: 15px 40px;
    @media screen and (max-width: 600px) {
      padding: 5px;
    }
  }
}