// Lato Font Import
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

// Variables
$color-primary: #11beef;
$color-primary-light: #11beef;
$color-primary-dark: #3484de;
$color-white: #fff;
$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;
$color-grey-dark-1: #999;
$color-grey-dark-2: #777;
$color-grey-dark-3: #333;
$color-black: #000;

// Font Sizes
$font-size-default: 1.6rem;

// Border Radius
$border-radius-small: 0.2rem;
$border-radius-medium: 0.3rem;
$border-radius-large: 5rem;

// Mixins
@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin gradient($color-1, $color-2) {
  background-image: linear-gradient(to right bottom, $color-1, $color-2);
}

.navigation {
  position: relative;
  width: 200px;
  height: 100%;
  // Buttons
  .btn {
    &,
    &:link,
    &:visited {
      position: relative;
      display: inline-block;
      padding: 1.5rem 4rem;
      text-decoration: none;
      text-transform: uppercase;
      font-size: $font-size-default;
      border-radius: $border-radius-large;
      backface-visibility: hidden;
      z-index: 0;

      transition: all 0.2s;

      // Changes for <button>
      border: none;
      cursor: pointer;
    }

    &::after {
      content: "";
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: $border-radius-large;
      z-index: -1;

      transition: all 0.4s;
    }

    &:hover,
    &:focus {
      transform: translateY(-0.3rem);
      box-shadow: 0 1rem 2rem rgba($color-black, 0.2);

      // Changes for <button> on :focus
      outline: none;

      &::after {
        opacity: 0;
        transform: scaleX(1.4) scaleY(1.6);
      }
    }

    &:active {
      transform: translateY(-0.1rem);
      box-shadow: 0 0.5rem 1rem rgba($color-black, 0.2);
    }

    &--white {
      background-color: $color-white;
      color: $color-grey-dark-2;

      &::after {
        background-color: $color-white;
      }
    }

    &--animated {
      animation: moveInBottom 0.5s ease-out 0.75s backwards;
    }
  }

  // Header Animations
  @keyframes moveInLeft {
    0% {
      opacity: 0;
      transform: translateX(-10rem);
    }
    80% {
      transform: translateX(1rem);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes moveInRight {
    0% {
      opacity: 0;
      transform: translateX(10rem);
    }
    80% {
      transform: translateX(-1rem);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes moveInBottom {
    from {
      opacity: 0;
      transform: translateY(3rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  // Navigation
  // Hidden Checkbox
  &__checkbox {
    display: none;

    // Navigation Functionality
    &:checked ~ .navigation__background {
      transform: scale(40);
    }

    &:checked ~ .navigation__nav {
      transform: translateX(0);
      visibility: initial;
      opacity: 1;
    }
  }

  // Input Label -> Styled as a Button
  &__button {
    position: absolute;
    top: 0;
    right: 0;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    cursor: pointer;
    z-index: 12;
    

    // Center the Icon
    text-align: center;
  }

  // Background Image / Gradient -> Hidden Behind a Label
  &__background {
    position: fixed;
    top: 0;
    left: 100%;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background-image: radial-gradient(
      $color-primary-light,
      $color-primary-dark
    );
    z-index: 10;

    transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1);
  }

  // Menu Container
  &__nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    overflow-y: scroll;
    width: 100%;
    z-index: 11;

    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;

    transition: opacity 0.8s ease-in,
      transform 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  // Unordered List
  &__list {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    /* top: 75%; */
    text-align: center;
    list-style: none;
    @media screen and (max-width: 680px) {
      top: 10%;
      padding: 0;
    }
  }

  // List Items with Automatic Counter
  &__item {
    margin: 1rem;
    counter-increment: list;
  }

  // Item Links
  &__link {
    // Automatic Counter
    &::before {
      content: "0" counters(list, "");
      padding-right: 1.2rem;
    }

    &:link,
    &:visited {
      display: inline-block;
      font-size: 2rem;
      font-weight: 300;
      white-space: nowrap;
      padding: 1rem 2rem;
      color: $color-white;
      text-decoration: none;
      text-transform: uppercase;
      background-image: linear-gradient(
        120deg,
        transparent 0%,
        transparent 49.9%,
        $color-white 50%
      );
      background-size: 227%;

      transition: all 0.4s;
      @media screen and (max-width: 680px) {
        font-size: 1.8rem;
      }
      @media screen and (max-width: 480px) {
        font-size: 1.4rem;
      }
    }

    &:hover,
    &:active {
      color: $color-primary;
      background-position: 100%;
      transform: translateX(1rem);
    }
  }

  // Icon Design
  &__icon {
    display: inline-block;
    position: relative;
    width: 3rem;
    height: 0.5rem;
    top: 37%;

    transition: transform 0.25s ease-in-out;

    &-span {
      position: absolute;
      height: 0.5rem;
      width: 50%;
      background: #f5f8fa;

      transition: all 0.5s ease-in;

      &:nth-child(even) {
        left: 50%;
        border-radius: 0 0.9rem 0.9rem 0;
      }
      &:nth-child(odd) {
        left: 0;
        border-radius: 0.9rem 0 0 0.9rem;
      }

      &:nth-child(1),
      &:nth-child(2) {
        transform: translateY(-1rem);
      }
      &:nth-child(5),
      &:nth-child(6) {
        transform: translateY(1rem);
      }
    }
  }

  &__checkbox:checked + &__button &__icon-span {
    &:nth-child(1),
    &:nth-child(6) {
      transform: rotate(45deg);
    }
    &:nth-child(2),
    &:nth-child(5) {
      transform: rotate(-45deg);
    }
  }

  &__checkbox:checked + &__button &__icon-span {
    &:nth-child(2),
    &:nth-child(6) {
      transform-origin: left;
    }
    &:nth-child(1),
    &:nth-child(5) {
      transform-origin: right;
    }
  }

  &__checkbox:checked + &__button &__icon-span {
    &:nth-child(3) {
      left: -50%;
      opacity: 0;
    }
    &:nth-child(4) {
      left: 100%;
      opacity: 0;
    }
  }

  &__button:hover &__icon-span {
    &:nth-child(3) {
      transform: translateX(-0.3rem) scale(1.1);
    }
    &:nth-child(4) {
      transform: translateX(0.3rem) scale(1.1);
    }
  }

  &__checkbox:checked + &__button:hover &__icon {
    transform: rotate(180deg);
  }
}
