$cubic: cubic-bezier(0.64, 0.09, 0.08, 1);
.team-feed-item {
  display: flex;
  flex-direction: row;
  min-height: 100px;
  padding: 10px;
  border: 0;
  border-radius: 1rem;
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
  text-decoration: none;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  color: #000000;
  background: #ececec;
  box-shadow: 1px 1px 9px -2px rgba(0, 0, 0, 0.53);
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.22) 0px 19px 43px;
    transform: translate3d(0px, -1px, 0px);
  }

  &:disabled {
    color: #a8abad;
    background: transparent;
  }
  &.current {
    box-shadow: rgba(0, 0, 0, 0.22) 0px 19px 43px;
    transform: translate3d(0px, -1px, 0px) scale(1.04);
  }

  &.selected {
    color: #ffffff;
    background: #0072ff;
    background: linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr="$one", endcolorstr="$two", gradienttype=1);

    &:hover {
      color: #ececec;
      filter: brightness(1.1);
    }

    &:disabled {
      color: #a8abad;
      background: transparent;
    }
  }

  &__name-container {
    display: flex;
    flex-direction: column;
    width: 30%;
    justify-content: center;
    text-align: center;
  }
  &__avatar {
    img {
      width: 50px;
    }
  }
  &__name {
    font-size: 20px;
    align-self: center;
    white-space: initial;
    color: #000000;
  }
  &__text-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  &__text {
    font-size: 22px;
    width: 100%;
    white-space: normal;
    font-weight: 500;
    // margin-left: 10px;
    align-self: flex-start;
  }
  &__bottom {
    display: flex;
    align-content: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    padding-top: 10px;
  }
  &__date{
    align-self: flex-end;
    color: #9c9a9a;
  }

}
