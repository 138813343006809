body {
  font-family: "Open Sans", sans-serif;
  background: rgb(14, 193, 240);
  background: linear-gradient(
    92deg,
    rgb(52, 132, 223) 0%,
    rgb(57, 183, 240) 123%
  );
}

body textarea {
  font-family: "Open Sans", sans-serif;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
  transform: scale(1.5);
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: all 1s ease-out;
  .slide-modal__body {
    width: 0;
  }
}
.fade-enter.fade-enter-done {
  .slide-modal__body {
    width: inherit;
  }
}

.fade-exit {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  opacity: 0.1;
  transform: scale(1);
  z-index: 1;
}
.fade-exit.fade-exit-active {
  transform-origin: 50% 50%;
  opacity: 0;
  transform: scale(0.7);
  transition: all 1s ease-out;
  .slide-modal__body {
    width: 0;
  }
}

@keyframes slidein {
  from {
    transform: translateX(calc(100vw * 1.5)) scale(0.8);
    transform-origin: 50% 50%;
  }

  50% {
    transform: translateX(0) scale(0.8);
  }

  to {
    transform: translateX(0) scale(1);
  }
}

.slide-enter {
  transform: translateX(calc(100vw * 1.5)) scale(0.8);
  transform-origin: 50% 50%;
}

.slide-enter.slide-enter-active {
  animation-duration: 1s;
  animation-name: slidein;
  animation-timing-function: ease-out;
  .chat-container__chat {
    width: 0;
  }
}

.slide-exit {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  opacity: 0;
  transform: scale(1);
  z-index: 1;
}
.slide-exit.slide-exit-active {
  transform-origin: 50% 50%;
  opacity: 0;
  transform: scale(0.7);
  transition: all 1s ease-out;
  .slide-modal__body {
    width: 0;
  }
}

.app-main {
  position: relative;
  height: 100vh;
  overflow: auto;
}

.app-container {
  position: relative;
  padding: 40px 60px;
  min-height: calc(100vh - 125px);
  overflow-y: auto;
  @media screen and (max-width: 800px) {
    padding: 40px 0;
  }

  /* background: #f6f8fa; */
  /* margin-top: 25px; */
}
.app-container.no-header {
  min-height: 100vh;
}
.app-main.show-header .app-container {
  min-height: calc(100vh - 125px);
}

.app-main:not(.dashboard) .app-container {
  // @media screen and (max-width: 600px) {//disable header only on small screens
  min-height: calc(100vh - 125px);
  max-width: 1100px;
  margin: 0 auto;
  // padding: 0;
  overflow: initial;
  // }
}
.app-main:not(.show-header) .header {
  //disable header only on small screens
  // @media screen and (max-width: 600px) {
  min-height: 100vh;
  position: absolute;
  box-shadow: none;
  pointer-events: none;
  .navigation {
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: all;
    height: 0%;
  }
  .header__small-screen-flex {
    visibility: hidden;
  }
  .navigation__icon-span {
    background: #868585;
  }
  // }
}
.app-main.team .header,
.app-main.forum .header {
  .navigation__icon-span {
    background: #fff;
  }
}

a {
  color: black;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media screen and (max-width: 700px) {
  .app-container {
    padding: 0;
  }
}

.accordion-cb {
  display: flex;
  flex: 1;
  background: #ffffff;
  flex-wrap: wrap;
  color: #676767;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #929292;
  border-radius: 10px;
  justify-content: space-between;
  min-height: 50px;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  label{
    display: flex;
    flex: 1 1;
  }
  input{
    position: absolute;
    pointer-events: none;
    visibility: hidden;
  }
  &-content {
    width: 100%;
    font-weight: 600;
    max-height: 0px;
    opacity: 0;
    transition: all 0.5s ease;

    iframe {
      width:100%;
    }
  }
  
  input:checked ~ &-content {
    opacity: 1;
    max-height: 5000px;
    margin-top: 5px;
    border-top: 1px dashed;
    padding-top: 10px;
  }

  &.active {
    max-height: 5000px;
  }
  &-name {
    width: 95%;
    // white-space: nowrap;
    overflow: hidden;
   
  }
  &-control {
    cursor: pointer;
    width: 5%;
    text-align: right;
  }
  @media screen and (max-width: 480px) {
    &-name {
      width: 65%;
    }
    &-control {
      width: 35%;
      i {
        padding: 0;
      }
    }
  }
  &-subtitle {
    font-size: 12px;
    color: #929292;
  }
 
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.standard-modal{
    
  &__title{
    font-size: 50px;
    font-weight: 600;
    color: #000;
    
  }
  &__text{
    margin: 25px 0;
  }
  &__preview{
    height: 100px;
    margin-bottom: 15px;
    img{
      height: 100%;
    }
  }
  &__body{
    color: #000;
    font-size: 30px;
    margin-bottom: 50px;
    // text-align: left;
    a{
      text-decoration: underline;
      color: #7c7c7c;
      font-size: 35px;
    }

  }
}