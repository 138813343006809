$cubic: cubic-bezier(0.64, 0.09, 0.08, 1);
.select-bubble-item {
  display: block;
  width: auto;
  height: 3rem;
  padding: 0 30px;
  border: 0;
  border-radius: 1rem;

  font-family: "Nunito Sans", -apple-system, blinkmacsystemfont, "Segoe UI",
    roboto, helvetica, arial, sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 3rem;
  white-space: nowrap;
  text-decoration: none;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  color: #000000;
  background: #ececec;
  box-shadow: 1px 1px 9px -2px rgba(0, 0, 0, 0.53);
  &:hover {
    color: #009cff;
  }

  &:disabled {
    color: #a8abad;
    background: transparent;
  }

  &.selected {
    color: #ffffff;
    background: #0072ff;
    background: linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr="$one", endcolorstr="$two", gradienttype=1);

    &:hover {
        color: #ececec;
        filter: brightness(1.1);
    }

    &:disabled {
      color: #a8abad;
      background: transparent;
    }
  }

  @media screen and (max-width: 680px) {
    border-radius: 9rem;
    font-size: 1rem;
    font-weight: 600;
  }
}
