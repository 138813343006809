$cubic: cubic-bezier(.64,.09,.08,1);
.dashboard-page {
    display: flex;
    flex-direction: column;
    align-items: left;
    max-width: 1500px;
    margin: 0 auto;
    color:#fff;
    @media screen and (max-width: 640px) {
      padding: 0 10px;
    }
    .title{
      font-weight: bold;
      font-size: 50px;
      margin-bottom: 40px;
      @media screen and (max-width: 520px) {
        font-size: 40px;
        margin-bottom: 0px;
      }
    }
    .sub-title{
      font-size: 20px;
      max-width: 600px;
      margin-bottom: 20px;
      @media screen and (max-width: 520px) {
        margin-bottom: 0px;
      }
    }
    &__empty-data{
      font-size: 40px;
      font-weight: bold;
      padding: 40px;
      text-align: center;
    }

  &__container{
      display: flex;
      flex-direction: row;
      margin-top: 40px;
      flex-wrap: wrap;
      @media screen and (max-width: 520px) {
        margin-top: 0px;
      }
      &_box{
        margin: 20px;
        width: 45%;
        min-height: 200px;
        padding: 30px;
        background-color: rgb(236, 236, 236);
        box-shadow: 1px 1px 21px -2px rgba(0, 0, 0, 0.53);
        @media screen and (max-width: 1100px) {
          width: 100%;
        }
        @media screen and (max-width: 520px) {
          margin: 15px 0 ;
        }
      }
      &_title{
        font-weight: bold;
        font-size: 25px;
      }
      
      &_body{
        width: 100%;
      }
      &_button{
        text-align: center;
        margin-top: 15px;
        .custom-button{
          font-size: 1rem;
        }
      }
  }

  
  


}
