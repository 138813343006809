.assessment-input-range {
  width: 100%;
  // background-color: rgb(236, 236, 236);
  // box-shadow: 1px 1px 21px -2px rgba(0, 0, 0, 0.53);
  padding: 20px;

  &__name{
    padding: 14px;
    font-size: 24px;
    font-weight: 600;
  }
  &__label-container{
    position: relative;
    cursor: pointer;
    display: flex;
    flex: 0 1;
    margin: 0px -1px;
  }
  &__label {
    font-size: 22px;
    font-weight: 500;
    padding-top: 7px;
    width: 11.11%;
    &:last-child{
      width: 2px;
      position: absolute;
      top: 0;
      right: 10px;
      @media screen and (max-width: 480px) {
        right: 4px;
      }
    }
    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }

  .input-range__track--background {
    background-color: #9c9c9c;
  }
  .input-range {
    width: 100%;
    margin: 0 auto;
  }
  .input-range__track {
    
    height: 0.6rem;
    &--active{
      background: #1c87e8;
    }
  }
  .input-range__slider {
    background: #1c87e8;
    border: 1px solid #1c87e8;
    height: 1.5rem;
    margin-top: -1rem;
    width: 1.5rem;
    @media screen and (max-width: 480px) {
      height: 1rem;
      margin-top: -0.8rem;
      width: 1rem;
    }
  }
  .input-range__label--value {
    top: -2.4rem;
  }
  &__chevron-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    svg{
      width: 50px;
    }
  }
  &__helper {
    position: absolute;
    top: 0;
    left: 0;

    animation: 4s linear 2s infinite alternate helper_cursor_animation;
    img{
      height: 50px;
      -moz-transform: scale(-1, -1);
      -o-transform: scale(-1, -1);
      -webkit-transform: scale(-1, -1);
      transform: scale(-1, -1);
    }
  }
  
}

@keyframes helper_cursor_animation {
  0%,
  20%,
  40%,
  50%,
  60%,
  80%,
  90%,
  to,
  from {
    // animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from{
    opacity: 0;
    left:0;
  }
  
  50% {
    opacity: 0.8;
    left:50%;
  }

  to {
    opacity: 0;
    left:100%;
  }
}