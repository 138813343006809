$cubic: cubic-bezier(0.64, 0.09, 0.08, 1);
.team-card {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 150px;
  padding: 20px;
  border: 0;
  border-radius: 1rem;
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
  text-decoration: none;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  color: #000000;
  background: #ececec;
  box-shadow: 1px 1px 9px -2px rgba(0, 0, 0, 0.53);
  border: 2px solid transparent;
  &.coach{
    //if coach then golden border around whole card
    border: 2px solid rgb(211, 177, 125);

  }
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.22) 0px 19px 43px;
    transform: translate3d(0px, -1px, 0px);
  }

  &:disabled {
    color: #a8abad;
    background: transparent;
  }
  &.current {
    box-shadow: rgba(0, 0, 0, 0.22) 0px 19px 43px;
    transform: translate3d(0px, -1px, 0px) scale(1.04);
  }

  &.selected {
    color: #ffffff;
    background: #0072ff;
    background: linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr="$one", endcolorstr="$two", gradienttype=1);

    &:hover {
      color: #ececec;
      filter: brightness(1.1);
    }

    &:disabled {
      color: #a8abad;
      background: transparent;
    }
  }

  &__name-container {
    display: flex;
    flex-direction: row;
  }
  &__avatar {
    img {
      width: 50px;
    }
  }
  &__name {
    font-size: 16px;
    margin-left: 10px;
    align-self: center;
    white-space: normal;
    text-align: center;
    color: #3484de;
  }
  &__coach {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 12px;
    //color dark golder 
    color: rgba(163, 133, 88, 0.599);
  }
  &__user-icon{
    margin-left: 10px;
    font-weight: 500;
  }
  &__progressbar_container {
    display: flex;
    height: 100%;
    align-items: center;
  }

  &__progressbar {
    width: 100%;
  }
  &__progress {
    background-color: #ced1d2;
    height: 1em;
    position: relative;
    width: 100%;
    border-radius: 10px;
  }

  &__progress-bar {
    background-size: 23em 0.25em;
    height: 100%;
    border-radius: 10px;
    position: relative;
    background-color: #337ab7;
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  }
}
