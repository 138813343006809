.checkmark-check {
  &__checkmark {
    align-self: center;
    width: 100%;
    height: 70%;
    
  }
  &__checkmark-check {
    stroke-dasharray: 80px 80px;
    stroke-dashoffset: -80px;
  }

  &.active  &__checkmark-check{
      animation: 1s checkAnimate 1s forwards
        cubic-bezier(0.895, 0.03, 0.685, 0.22);
  }

  
}

@keyframes checkAnimate {
  0% {
    stroke-dashoffset: -80px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}