.form-checkbox {
  &__checkbox {
    position: relative;
    height: 40px;
    width: 40px;
    cursor: pointer;
    line-height: 20px;
    display: inline;
    color: #666;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 26px;
      height: 26px;
      border: 1px solid #ddd;
      background: #fff;
    }
    &::after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
      content: "";
      width: 16px;
      height: 16px;
      // border-radius: 50%;
      background: #038aff;
      position: absolute;
      top: 6px;
      left: 6px;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    &.checked{
      &::after{
        opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
      }
    }
  }
  &__label{
    display: inline;
    margin-left: 40px;
    
    a,.link{
      cursor: pointer;
      text-decoration: underline;
      color: #038aff;
      font-weight: 600;
      transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
      &:hover{
        color: darken(#038aff,20%)
      }
      &:active{
        color: darken(#038aff,50%)
      }
    }
  }
}
