$cubic: cubic-bezier(0.64, 0.09, 0.08, 1);
.quiz-answer {
  display: block;
  width: auto;
  // min-height: 3rem;
  padding: 1rem 30px;
  border: 0;
  border-radius: 1rem;


  font-family: "Nunito Sans", -apple-system, blinkmacsystemfont, "Segoe UI",
    roboto, helvetica, arial, sans-serif;
  font-size: 16px;
  font-size: 1.1rem;
  font-weight: 400;
  // line-height: 3rem;
  // white-space: nowrap;
  text-decoration: none;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  color: #000000;
  background: #ececec;
  box-shadow: 1px 1px 9px -2px rgba(0, 0, 0, 0.53);
  &:hover {
    color: #009cff;
  }

  &:disabled {
    color: #a8abad;
    background: transparent;
  }

  &.selected {
    color: #ffffff;
    
    &.correct{
      background: #13be47;
      background: linear-gradient(135deg, #13be47 0%, #089b34 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr="$one", endcolorstr="$two", gradienttype=1);
    }
    &.wrong{
      background: #f78b60;
      background: linear-gradient(135deg, #f78b60 0%, #ff5d1d 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr="$one", endcolorstr="$two", gradienttype=1);
    }
    &:hover {
        color: #ececec;
        filter: brightness(1.1);
    }

    &:disabled {
      color: #a8abad;
      background: transparent;
    }
  }

  @media screen and (max-width: 680px) {
    font-size: .8rem;
    height: auto;
    white-space: initial;
    line-height: 1.4rem;
    padding: 15px 30px;
  }
}
