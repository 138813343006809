.slide-modal {
  &__close-button {
    cursor: pointer;
    position: absolute;
    cursor: pointer;
    right: 20px;
    top: 20px;
  }
  &.active &__overlay {
    opacity: 1;
    pointer-events: all;
  }
  &.active &__body{
    transform: translateX(-40vw);
    @media screen and (min-width: 1300px) {
      transform: translateX(-601px);
    }
    @media screen and (max-width: 750px) {
      transform: translateX(-100vw);
    } 
  }
&__overlay {
  
  pointer-events: none;
  transition: opacity 0.6s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  z-index: 510;
  background-color: rgba(0, 0, 0, 0.08);
  left: 0px;
  top: 0px;
  opacity: 0;
  cursor: pointer;
  position: fixed;
  height: 100%;
  width: 100%;
}

&__body{
  transform: translateX(0px);
  transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1) 0s, opacity 0.6s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 12px;
  left: 100%;
  background-color: white;
  z-index: 520;
  width: 40vw;
  top: 0px;
  opacity: 1;
  position: fixed;
  height: 100%;
  box-sizing: border-box;
  overflow: auto; 
  @media screen and (min-width: 1300px) {
    width: 600px;
  } 
  @media screen and (max-width: 750px) {
    width: 100vw;
  } 
  
}
}