.sign-in{
    width: 100%;
    display: flex;
    flex-direction: column;
    .footnote-signup{
        text-align:center;
        font-weight: 600;
        font-size:11px;
        padding-top:10px;
      }    
    &__forgot-password{
        position: absolute;
        cursor: pointer;
        bottom: -25px;
        left: 5px;
        font-size: 14px;
        font-weight: bold;
        color: #0baaff;
        &:hover{
            text-decoration: underline;
            color: #50c2ff;
        }
    }
    &__email-outer{
        position: relative;
    }
}

.title{
    font-weight: 600;
    font-size: 20px;
}

.buttons{
    display: flex;
    justify-content: center;
}



