$cubic: cubic-bezier(0.64, 0.09, 0.08, 1);

.collaborative-way-page {
  display: flex;
  flex-direction: column;
  align-items: left;
  max-width: 1500px;
  margin: 0 auto;

  @media screen and (max-width: 800px) {
    padding: 5px 5px;
  }
  @media screen and (max-width: 640px) {
    padding: 15px 0;
  }
  .breadcrumb {
    // width: 40%;
    max-width: 800px;
    transition: transform 1s ease-in-out,opacity .5s ease;
    opacity: 1;
    &.change{
      transform: translateX(-3000px);
      opacity: 0;
    }
  }
  .title {
    font-weight: bold;
    font-size: 50px;
    margin-bottom: 40px;
    @media screen and (max-width: 520px) {
      font-size: 40px;
      margin-bottom: 0px;
    }
  }
  .sub-title {
    font-size: 20px;
    max-width: 600px;
    margin-bottom: 20px;
    @media screen and (max-width: 520px) {
      margin-bottom: 0px;
    }
  }
  &__container {
    width: 100%;
    padding: 40px;
    // margin: 20px;
    border: 0;
    opacity: 1;
    border-radius: 1rem;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    text-align: left;
    transition: transform 1s ease-in-out,opacity .5s ease;
    outline: none;
    color: #000000;
    background: #ececec;
    box-shadow: 1px 1px 9px -2px rgba(0, 0, 0, 0.53);
    @media screen and (max-width: 520px) {
      padding: 60px 10px;
    }
    a{
      color: #3585df;
      text-decoration: underline;
    }
  }
  
}
