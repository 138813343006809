$cubic: cubic-bezier(0.64, 0.09, 0.08, 1);
.session-item {
  width: auto;
  max-width: 1100px;
  margin: 0 auto;
  border: 0;
  opacity: 1;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  text-align: left;
  transition: transform 1s ease-in-out, opacity 0.5s ease;
  outline: none;
  color: #000000;

  &__content {
    background: #ffffff;
    padding: 40px;
    border-radius: 10px;
    margin-bottom: 30px;
    @media screen and (max-width: 800px) {
      padding: 5px;
      // border-radius: 0;
    }
    @media screen and (max-width: 600px) {
      padding: 10px;
      // border-radius: 0;
    }
  }

  // &.change{
  //   transform: translateX(calc(100vw*1.5));
  //   opacity: 0;
  // }

  h1 {
    font-size: 36px;
    font-weight: 700;
    color: #12b0f9;
    margin: 10px 0;
    line-height: 40px;
    @media screen and (max-width: 600px) {
      font-size: 40px;
      // line-height: 46px;
    }
  }

  h2 {
    font-size: 36px;
    font-weight: bold;
    // line-height: 46px;
    color: #12b0f9;
    margin: 30px 0;
    @media screen and (max-width: 600px) {
      font-size: 28px;
      // line-height: 28px;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 16px;
    font-size: 18px;
    line-height: 30px;
    color: #000000;
    white-space: pre-wrap;
    -webkit-font-smoothing: subpixel-antialiased;
    @media screen and (max-width: 600px) {
      // font-size: 14px;
    }
    white-space: pre-wrap;
    .bb-code-text-block {
      display: inline;
    }
    table {
      -webkit-font-smoothing: antialiased;
      overflow: auto;
      width: 100%;

      th {
        background-color: #c9eafb;
        font-weight: normal;
        padding: 20px 30px;
        text-align: center;
        @media screen and (max-width: 480px) {
          padding: 15px;
        }
        &:first-of-type {
          border-top-left-radius: 10px;
        }
        &:last-of-type {
          border-top-right-radius: 10px;
        }
      }
      td {
        background-color: rgb(238, 238, 238);
        color: rgb(0,0, 0);
        padding: 20px 30px;
        @media screen and (max-width: 480px) {
          padding: 15px;
        }
      }
    }

    &-center {
      text-align: center;
    }
    &-right {
      text-align: right;
    }
    &-left {
      text-align: left;
    }
    &-link {
      display: inline;
      text-decoration: underline;
      color: #00bcff;
      div {
        display: inline;
      }
    }
    &-image {
      max-width: 100%;
    }
    &-box {
      padding: 25px;
      background: #f5f5f5;
      // color:#fff;
      margin: 30px 0;
      width: 100%;
      border-radius: 10px;
      h1 {
        font-size: 36px;
        font-weight: 700;
        color: #313131;
      }
    }

    &-chat-button-container {
      min-width: 300px;
      min-height: 150px;
      width: 100%;
      text-align: center;
      // display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 25px;
      // font-size: 20px;
      // color: #000;
      text-decoration: none;
      margin: 30px 0;
      transition: all 0.6s;
      border-radius: 10px;
      background: #c9eafb;
      //     background: linear-gradient(
      // 150deg
      // , rgba(170,222,36,1) 19%, rgba(106,167,5,1) 86%);
      @media screen and (max-width: 540px) {
        // font-size: 16px;
        padding: 15px;
      }
    }
    &-note-button-container {
      background: #fff293;
      // background: linear-gradient(
      // 150deg
      // , rgba(212,79,182,1) 19%, rgba(221,26,96,1) 86%);
    }
    &-chat-button-body {
      display: flex;
      flex-direction: row;
      flex: 1 1;
      width: 100%;
    }
    &-chat-button-text {
      width: 100%;
      text-align: left;
      margin: 0 15px;
    }
    & &-chat-button-icon {
      width: 80px;
      color: #12b0f9;
      text-align: left;
      align-self: flex-start;
      i {
        font-size: 60px;
      }
      @media screen and (max-width: 540px) {
        width: 70px;
      }
    }

    &-chat-button {
      display: inline-block;
      padding: 15px;
      font-size: 16px;
      background: #fff;
      color: #000;
      min-width: 150px;
      text-decoration: none;
      margin-top: 20px;
      transition: all 0.6s;
      text-align: center;
      border-radius: 120px;
      cursor: pointer;
      background: linear-gradient(135deg, #00c6ff 0%, #0072ff 160%);
      color: #fff;
      font-weight: bold;
      @media screen and (max-width: 540px) {
        margin-top: 10px;
      }
      &:hover {
        background: rgb(231, 231, 231);
        color: #009cff;
        background: transparent;
        box-shadow: inset 0 0 0 2px #009cff;
      }
      &:active {
        background: rgb(202, 202, 202);
        color: #65c4ff;
        background: transparent;
        box-shadow: inset 0 0 0 2px #009cff;
      }
    }
    &-quote {
      position: relative;
      // font-family: sans-serif;
      line-height: 24px;
      width: 100%;
      background: linear-gradient(90deg, #fbdc6a 40%, #eabf46 60%);
      border-radius: 10px;
      padding: 25px;
      text-align: left;
      margin: 30px 0 50px 0;
      @media screen and (max-width: 400px) {
        margin-left: 0;
      }
      // color: #000;
      transition: all 0.3s ease;

      &:before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 24px solid;
        border-left-color: #fbdc6a;
        border-right: 12px solid transparent;
        border-top: 12px solid;
        border-top-color: #fbdc6a;
        border-bottom: 20px solid transparent;
        left: 32px;
        bottom: -24px;
      }
    }
  }

  &__image {
    margin: 30px 0;
    width: 100%;
    // border-radius: 10px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  &__video {
    margin: 30px 0;
  }
  margin: 30px 0;

  &__controls {
    display: flex;
    flex-direction: column;
    background: #5d5d5d;
    border-radius: 10px;
    padding: 10px;
    min-height: 300px;
    @media screen and (max-width: 600px) {
      min-height: 0;
    }
  }
  &__top-controls {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    align-content: space-between;
    justify-content: space-between;
    text-align: center;
    color: #eee;
    font-size: 24px;
    @media screen and (max-width: 800px) {
      font-size: 16px;
    }
    @media screen and (max-width: 600px) {
      margin: 30px 0;
    }
  }
  &__badge {
    display: none;
    width: 25px;
    height: 25px;
    position: absolute;
    font-size: 15px;
    border-radius: 25px;
    // top: 0;
    // left: 72%;
    top: 17%;
    left: 50%;
    transform: translateX(-50%);
    // border: 2px solid;
    text-align: center;
    @media screen and (max-width: 600px) {
      display: block;
    }
  }
  &__chat,
  &__note,
  &__bookmark {
    // padding:5px;
    position: relative;
    cursor: pointer;
    align-self: center;
    width: 35%;
    transition: all 0.3s ease;
    &:hover {
      color: rgb(175, 174, 174);
    }
    @media screen and (max-width: 800px) {
      width: 49%;
    }
    @media screen and (max-width: 600px) {
      span {
        display: none;
      }
    }
  }
  &__note {
    &.active {
      color: #00bcff;
    }
  }
  span {
    vertical-align: top;
  }
  i {
    &.active {
      color: #00bcff;
    }
    font-size: 25px;
    padding: 0 10px;
    @media screen and (max-width: 600px) {
      font-size: 40px;
    }
  }

  &__bottom-controls {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: center;
    flex: 1 1;
    text-align: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__button {
    align-self: center;
    width: 33%;
    position: relative;
  
    &.inactive {
      .custom-button {
        pointer-events: none;
      }
    }
    @media screen and (max-width: 600px) {
      &:nth-child(2) {
        order: -1;
        width: 100%;
      }
      &:nth-child(1),
      &:nth-child(3) {
        width: 48%;
      }
    }

    .custom-button {
      position: relative;
      font-size: 20px;
      @media screen and (max-width: 1100px) {
        font-size: 16px;
      }
      @media screen and (max-width: 800px) {
        font-size: 14px;
      }
      @media screen and (max-width: 600px) {
        font-size: 16px;
        margin: 10px 0;
        width: 100%;
        padding: 0;
      }
    }
    .checkmark-check {
      height: 100px;
      @media screen and (max-width: 600px) {
        height: 70px;
      }
    }
  }
  .assessment {
    margin: 30px auto;
  }
  .quiz {
    margin: 30px 0;
  }
  @keyframes bounceIn2 {
    0%,
    20%,
    40%,
    60%,
    80%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3) translate(-50%, -50%);
    }

    20% {
      transform: scale3d(1.1, 1.1, 1.1) translate(-50%, -50%);
    }

    40% {
      transform: scale3d(0.9, 0.9, 0.9) translate(-50%, -50%);
    }

    60% {
      opacity: 1;
      transform: scale3d(1.03, 1.03, 1.03) translate(-50%, -50%);
    }

    to {
      opacity: 1;
      transform: scaleX(1) translate(-50%, -50%);
    }
  }

  @keyframes note-modal-fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes note-modal-fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  &__note-modal {
    &.exiting {
      &.modal-popup {
        animation: note-modal-fadeout ease-out 1s;
      }
    }

    &.modal-popup .modal-popup__container {
      transform-origin: 10%;
      background-color: #fff293;
      box-shadow: 0px 4px 16px 6px rgba(255, 255, 53, 0.3);
      border-radius: 20px;
      transform: translate(-50%, -50%);
      animation: bounceIn2 ease 1s;
    }
    &.modal-popup .modal-popup__container .modal-popup__body {
      padding: 15px 40px;
      @media screen and (max-width: 600px) {
        padding: 5px;
      }
    }
  }
  &__note-modal-title {
    color: #000;
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 20px;
  }
  &__note-modal-buttons-container {
    .custom-button {
      margin: 10px;
      @media screen and (max-width: 600px) {
        font-size: 0.7rem;
      }
      font-size: 1rem;
    }
  }

  &__note-modal-textarea {
    width: 80%;
    white-space: pre-wrap;
    min-height: 400px;
    outline: none;
    padding: 20px;
    font-size: 30px;
    font-weight: 500;
    border-radius: 15px;
    box-shadow: 0 0 0 2px rgb(136, 136, 136);
    border: 5px solid transparent;
    @media screen and (max-width: 600px) {
      width: 98%;
    }
  }
  &__note-modal-input-name {
    width: 80%;
    white-space: pre-wrap;
    outline: none;
    padding: 20px;
    margin: 10px 0;
    font-size: 30px;
    font-weight: 500;
    @media screen and (max-width: 600px) {
      width: 98%;
    }
  }
}

.interactions {
  &__container {
    position: relative;
    cursor: pointer;
    margin-left: 10px;
    pointer-events: all;
    position: absolute;
    right: 15px;
    top: 12px;
  }
  &__container:hover &__interactions {
    opacity: 1;
    z-index: 12;
    pointer-events: all;

  }
  &__interactions {
    position: absolute;
    overflow: scroll;
    
    min-width: 320px;
    max-height: 320px;
    background: #ececec;
    right: 0;
    bottom: 35px;
    border-radius: 10px;
    border: 1px solid #929292;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    transition: all 0.5s ease-in-out;
    @media screen and (max-width: 480px) {
      right: -25px;
    }
  }
  &__item {
    display: flex;
    padding: 20px 0;
    border-bottom: 1px solid #ded9d9;
    flex: 1;
    padding: 0;
    flex: 1 1;    
    font-size: 15px;
    // max-height: 300px;
  }
  &__item-icon {
    width: 20%;
    pointer-events: all;
    i{
      vertical-align: middle;
      padding: 2px;
    }
    & i.green {
      color: #1ca501;
      font-size: 24px;
    }
    & i.red {
      color: #ea0000;
      margin-left: 2px;
      font-size: 28px;
    }
  }
  &__item-name {
    width: 80%;
  }
  &__interactions-icon {
    position: relative;
    border-radius: 35px;
    width: 35px;
    height: 35px;
    background: #b9b9b9;
    color: #ececec;
    i {
      position: absolute;
      font-size: 20px;
      top: 50%;
      left: 49%;
      transform: translate(-50%, -50%);
    }

  }
  @media screen and (max-width: 1100px) {
    &__interactions-icon {
      border-radius: 30px;
      width: 30px;
      height: 30px;
      i {
        font-size: 20px;
      }
    }
    &__container {
      // right: 35px;
      top: 8px;
      font-size: 12px;
    }
    &__interactions {
      right: 0;
      bottom: 0px;
    }
    
  }
  @media screen and (max-width: 1000px) {
    &__container {
      right: 15px;
    }
  }
  @media screen and (max-width: 900px) {
    &__container {
      // right: -7px;
    }
  }
  @media screen and (max-width: 800px) {
    &__container {
      // right: 6px;
      top:6px;
    }
    &__interactions {
      right: 0;
      bottom: 0px;
    }
  }
  @media screen and (max-width: 740px) {
    &__container {
      right: 10px;
      // top:6px;
    }
    &__interactions {
      // right: 0;
      // bottom: 0px;
    }
  }
  
  @media screen and (max-width: 700px) {
    &__container {
      // right: 0px;
      top:6px;
    }
  }
  @media screen and (max-width: 600px) {
    &__container {
      right: 16px;
      top:9px;
    }
  }
  @media screen and (max-width: 340px) {
    &__container {
      // right: 10px;
      // top:6px;
    }
    &__interactions {
      right: -30px;
      // bottom: 0px;
    }
  }
}
