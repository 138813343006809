$cubic: cubic-bezier(0.64, 0.09, 0.08, 1);
.account-page {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  color: #fff;
  @media screen and (max-width: 640px) {
    padding: 0 10px;
  }
  @media screen and (max-width: 600px) {
    .collab-nav-buttons{
      margin-top: 70px;
    } 
  }
  &__container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    margin: 0 auto;
    max-width: 1100px;
    width: 100%;
    border: 0;
    border-radius: 1rem;
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    text-decoration: none;
    text-align: left;
    transition: all 0.3s ease;
    outline: none;
    color: #000000;
    background: #ececec;
    box-shadow: 1px 1px 9px -2px rgba(0, 0, 0, 0.53);
  }
  &__avatar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    text-align: center;
  }
  &__avatar {
    cursor: pointer;
    img {
      width: 150px;
    }
  }
  &__avatar-text {
    cursor: pointer;
    font-size: 20px;
    color: #808080;
    cursor: pointer;
}
&__fields-container, &__access-codes-add{
  .group{
    .form-input{
      background-color: #ececec;
    }
  }
}
&__access-codes-container{
  padding: 20px;
}
&__access-codes-title{
  font-size: 26px;
  font-weight: 600;
}
&__access-codes-selector{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: space-between;
  padding: 20px;
  
}
&__access-code{
  position: relative;
  background: #5c5c5c;
  color: #fff;
  padding: 25px 35px;
  margin: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover{
    background: #4b4b4b;
  };
}
&__access-code-title{
  font-size: 26px;
  font-weight: 600;
  white-space: normal;
}
&__access-code-delete{
  position: absolute;
  top:5px;
  right: 10px;
  font-size: 26px;
  font-weight: 600;
  transition: all 0.3s ease;
  &:hover{
    color: #fc5151;
  }
}

&__access-codes-buttons{
  text-align: center;
}
&__access-codes-button{
  display: inline;
  margin: 10px;
}
&__modal-delete-message{
  color: #000;
  font-size: 36px;
  font-weight: 600;
  padding: 20px;
}
&__modal-delete-buttons{
  .custom-button{
    margin: 10px;
  }
}
}