.toggle-button{
   margin: 0;
   padding: 0;
   font: 16px 'Source Sans Pro', sans-serif;
   color: #454343;
   background: #f3f3f3;
    &__conatiner{
      
    }

    input[type="radio"] {
      display: none;
   }
   
   label {
      display: inline-block;
   }

    input[type="radio"] + label {
        position: relative;
        width: 50%;
        font-size: 1rem;
        line-height: 2.375rem;
        text-align: center;
        text-shadow: 0 1px 0 white;
        cursor: pointer;
        transition: all .5s;
        box-sizing: border-box;
        box-shadow: inset 0 5px 2px 0 rgba(255,255,255,.6), inset 0 0 0 1px #b8b8b8, 0 2px 2px 0 rgba(0,0,0,.4);
        background: #fbfbfb;
        background: -webkit-linear-gradient(top, #fbfbfb 0%,#dddddc 100%);
        background: -moz-linear-gradient(top, #fbfbfb 0%, #dddddc 100%);
        background: -o-linear-gradient(top, #fbfbfb 0%,#dddddc 100%);
        background: -ms-linear-gradient(top, #fbfbfb 0%,#dddddc 100%);   
        background: linear-gradient(to bottom, #fbfbfb 0%,#dddddc 100%);
        z-index: 1;   
     }
     
     label[for="popular"] {
        border-radius: 5px 0 0 5px;
     }
     
     label[for="recent"] {
        margin-left: -1.818181818%;
        border-radius: 0 5px 5px 0;
     }
     
     input[type="radio"]:checked + label {   
        box-shadow: inset -1px 2px 2px 0 rgba(0,0,0,.3), inset 0 0 0 1px #e8e8e8;   
        color: #8c8c8c;
        background: #e5e4e4;
        background: -webkit-linear-gradient(top, #e5e4e4 1%,#f1f1f1 100%);
        background: -moz-linear-gradient(top, #e5e4e4 1%, #f1f1f1 100%);
        background: -o-linear-gradient(top, #e5e4e4 1%,#f1f1f1 100%);
        background: -ms-linear-gradient(top, #e5e4e4 1%,#f1f1f1 100%);
        background: linear-gradient(to bottom, #e5e4e4 1%,#f1f1f1 100%);   
        z-index: 1;
     }
    
}