$cubic: cubic-bezier(0.64, 0.09, 0.08, 1);
.forum-page {
  display: flex;
  flex-direction: column;
  align-items: left;
  max-width: 1500px;
  margin: 0 auto;
  color: #fff;
  @media screen and (max-width: 640px) {
    padding: 0 10px;
  }
  @media screen and (max-width: 600px) {
    .collab-nav-buttons{
      margin-top: 70px;
    } 
  }
  &__container {
    display: flex;
    flex: 1 1;
    @media screen and (max-width: 420px) {
      flex-direction: column;
    }
  }
  &__tags-fake{
    display: flex;
    flex-direction: column;
    margin: 20px;
    margin-left: 0;
    min-width: 210px;
  }
  &__tags{
    display: flex;
    flex-direction: column;
    margin: 20px;
    margin-left: 0;
    width: 20%;
    @media screen and (max-width: 740px) {
      margin: 0;
    }
    @media screen and (max-width: 420px) {
      flex-direction: row;
      width:100%;
      flex-wrap: wrap;
      
    }
  }
  &__tag-fake{
    margin: 5px;
    margin-left: 20px;
    padding: 15px;
    font-size: 24px;
    font-weight: 600;
    border-radius: 10px;
  }
  &__tag{
    margin: 5px;
    margin-left: 20px;
    padding: 15px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
    background: #525252;
    color: #fff;
    min-width: 200px;
    transition: all 1s ease-out;
    @media screen and (max-width: 920px) {
      min-width: 0;
    }
    @media screen and (max-width: 740px) {
      font-size: 20px;
      padding: 10px;
    }
    @media screen and (max-width: 520px) {
      font-size: 16px;
      padding: 5px;
      margin-left: 0;
    }
    @media screen and (max-width: 420px) {
      min-width: 40px;
      font-size: 26px;
      padding: 10px;
      margin: 5px;
      &--add{
        font-size: 16px;
      }
    }
    cursor: pointer;
    &:hover{
        background:  #1e4377
    }
    &:active{
      background: #4a7bc0
    }
    &.active{
      background: #4087ec;
      border: 1px solid #fff;
    }
    &.edit{
      background: #d87b02;
    }
    &--add{
      text-align: center;
      font-size: 26px;
      font-weight: 600;
    }
  }
  &__add-tag-input{
    background: #d87b02;
    border: 0;
    outline: 0;
    color: #fff;
    font-size: 24px;
    width: 100%;
  }
  &__chat-fake{
    display: flex;
    width: 100%;
    margin-top: 20px;
    height: 540px;
    margin-left: 5px;
    color: #000;
  }
  &__chat{
    display: flex;
    width: 100%;
    margin: 5px;
    margin-left: 20px;
    color:#000;
    @media screen and (max-width: 1100px) {
      width: 100%;
    }
    @media screen and (max-width: 540px) {
      margin: 0px;
    }
    .spk-chat{
      width: 100%;
      &__header{
        background: #525252;
        color:#fff;
      }
      &__title{

      }
      &__main-panel{
        @media screen and (max-width: 740px) {
          padding: 0;
        }
      }
      
    }
   
  }
  &__no-access{
    font-size: 30px;
    text-align: center;
    padding: 50px;
    @media screen and (max-width: 640px) {
      padding: 10px;
      font-size: 20px;
    }
  }
}


@keyframes placeHolderShimmer{
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
}
.linear-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee52 8%, #dddddd52 18%, #eeeeee52 33%);
  background-size: 1000px 104px;
  min-height: 320px;
  width: 100%;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}