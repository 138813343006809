$cubic: cubic-bezier(0.64, 0.09, 0.08, 1);
.quiz {
  width: auto;
  padding: 25px;
  max-width: 1300px;
  margin: 0 auto;
  border: 0;
  border-radius: 1rem;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  text-align: left;
  transition: all 0.3s ease;
  outline: none;
  color: #000000;
  margin: 20px 0;
  background: #ececec;
  box-shadow: 1px 1px 9px -2px rgba(0, 0, 0, 0.53);
  // box-shadow: 1px 1px 9px -2px rgba(0, 0, 0, 0.53);
  @media screen and (max-width: 800px) {
    padding: 10px;
    border-radius: 0;
  }
  &__title{
    font-size: 24px;
  }
  &__desc{
    font-size: 16px;
    font-weight: 300;
  }
  &__text {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 25px;
    
  }
  .custom-button{
    font-size: 1rem;
    @media screen and (max-width: 440px) {
      font-size: .8rem;
    }
    &.hided{
      visibility: hidden;
    }
  }
 
  &__question-text{
    font-size: 18px;
    padding: 10px 0;
    font-weight: 700;
    text-align: left;
    transition: all 0.3s ease;
    opacity: 1;
    &.faded{
      opacity: 0;
    }
  }
  &__question-subtext{
    font-size: 16px;
    font-weight: 300;
  }
  &__answers-container{
    transition: all 0.3s ease;
    opacity: 1;
    &.faded{
      opacity: 0;
    }
  }
  &__finished-container{
    display: flex;
    flex-direction: column;
    padding: 24px;
    font-size: 18px;
  }
  &__finished-controls{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    @media screen and (max-width: 540px) {
      margin-top: 20px;
      flex-direction: column;
      flex: 1;
    }
  }
  &__finished-control{
    @media screen and (max-width: 540px) {
      margin: 20px 0;
    }
  }
  &__finished-button{
    cursor: pointer;
    transition: all 1s ease;
    &:hover{
      color: #8a8686;
      transform: scale(1.2);
    }
  }

  &__question-feedback{
    position: relative;
    font-family: sans-serif;
    font-size: 18px;
    line-height: 24px;
    width: 300px;
    background: #fff;
    border-radius: 20px;
    padding: 24px;
    text-align: left;
    margin-bottom: 35px;
    margin-left: 56px;
    @media screen and (max-width: 400px) {
      margin-left: 0;
    }
    color: #000;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease;
    &:before {
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
      border-left: 24px solid #fff;
      border-right: 12px solid transparent;
      border-top: 12px solid #fff;
      border-bottom: 20px solid transparent;
      left: 32px;
      bottom: -24px;
    }
    &.show{
      pointer-events: all;
      opacity:1;
    }
    &-user-choice{
      width: 100%;
      text-align: left;
      background: #dadada;
      margin-left: 0;
      &:before{
        border: 0;
      }
      
    }
  }
  &__controls{
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-between;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease;
    &.show{
      pointer-events: all;
      opacity:1;
    }
  }
  &__control {
    padding: 7px;
    background: #e4e4e4;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.3s ease;
    &:hover{
      background: #c4c4c4;
    }
    &:active{
      background: #c7c7c7;
    }
    &.disabled{
      visibility: hidden;
    }
  }
  &__control &__control-icon{
    font-size: 45px;
  }
  
}

.user-choice{
  .quiz-answer {
    &.selected {
      &.correct.standard{
        background: #46a6ff;
        background: linear-gradient(135deg, #46a6ff 0%, #1584ec 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr="$one", endcolorstr="$two", gradienttype=1);
      }
      &.wrong.standard{
        background: #46a6ff;
        background: linear-gradient(135deg, #46a6ff 0%, #1584ec 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr="$one", endcolorstr="$two", gradienttype=1);
      }
    }
  }
 
}

