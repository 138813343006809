.notifications{
    position: fixed;
    top: 130px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
}

.notifications-item{
    // text-align: center;
    color:rgba(255, 255, 255, 0);
    font-size: 0;
    font-weight: 600;
    background-color: rgb(184, 183, 183);
    border-radius: 10px;
    
    width: 500px;
    @media only screen and (max-width: 580px){
        width: 320px;
    }
    box-shadow: 1px 2px 11px -2px rgba(0,0,0,0.53);
    visibility: hidden;
    opacity: 0;
    transition: all 1s linear;
    transform: translateY(-150px) scaleY(0);
    transform-origin: top;
    // max-height: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;

    &.danger{
        color:#fff;
        background-color: rgb(250, 142, 142);
    }
    &.warning{
        color:#000;
        background-color: rgb(235, 226, 104);
    }
    &.normal{
        color:#000;
        background-color: rgb(212, 212, 206);
    }
    &.success{
        color:rgb(255, 255, 255);
        background-color: rgb(71, 209, 58);
    }
    &.primary{
        color:#fff;
        background-color: rgb(33, 109, 209);
    }
    &.show{
        padding: 20px;
        visibility: visible;
        opacity: 1;
        line-height: 20px;
        font-size: 20px;
        transform: translateY(0px) scaleY(1);
        // max-height: inherit;
        margin-top: 20px;
    
    }
}