.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 80px;
  .subtitle {
    font-size: 25px;
    margin-bottom: 25px;
  }
  .title {
    font-weight: bold;
    font-size: 50px;
    margin-bottom: 40px;
    color: #07abff;
    @media screen and (max-width: 520px) {
      font-size: 30px;
    }
  }
  .description {
    font-size: 20px;
    margin-bottom: 40px;
    text-align: center;
    @media screen and (max-width: 520px) {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 520px) {
    padding: 0 5px;
  }
  .collaboration-app-homepage-logo {
    max-width: 600px;
    cursor: pointer;
  }

  .middle_container {
    width: 80%;
    text-align: center;
    // max-width: 800px;
    // max-height: 600px;
    margin: 10% auto;
    border-radius: 25px;
    padding: 60px;
    background-color: #fff;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transition: all 1s ease-out;
    &.signup {
      max-height: 3000px;
    }
    &.signin {
      max-height: 800px;
    }
    .sign-up,.sign-in  {
      margin: 0 auto;
      max-width: 480px;

      .group .form-input {
        background: none;
        background-color: #ffffff;
      }
    }

    button.signin-link {
      display: block;
      
      margin: 20px auto;
      border: none;
      background: #fff;
      color: #049dff;
      font-size: 16px;
      cursor: pointer;
      /* width: 100%; */
      &:hover{
        text-decoration: underline;
      }
  }

  @media screen and (max-width: 820px) {
    width: 100%;
    padding: 30px;
    max-height: 1000px;
  }

  }
}
