.custom-button {
  display: inline-block;
  width: auto;
  height: 3em;
  padding: 0 3.333333em;
  border: 0;
  border-radius: 9rem;
  color: #ffffff;
  box-shadow: 0 0.25rem 2rem -0.5rem rgba(255, 255, 255, 0);
  font-family: 'Nunito Sans', -apple-system, blinkmacsystemfont, 'Segoe UI', roboto, helvetica, arial, sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 3;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
  background: #0072ff;
  outline: none;
  background: linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr="$one", endcolorstr="$two", gradienttype=1);

  &:hover {
    color: #009cff;
    background: transparent;
    box-shadow: inset 0 0 0 2px #009cff;
  }

  &:disabled {
    color: #a8abad;
    background: transparent;
    box-shadow: inset 0 0 0 2px #a8abad;
  }




  &.inverted {
    color: #009cff;
    background: transparent;
    box-shadow: inset 0 0 0 2px #009cff;

    &:hover {
      color: #ffffff;
      background-color: #009cff;
      box-shadow: none;
    }

    &:disabled {
      color: #a8abad;
      background: transparent;
      box-shadow: inset 0 0 0 2px #a8abad;
    }


  }

  &.red {
    color: #CD104D;
    background: transparent;
    box-shadow: inset 0 0 0 2px #CD104D;

    &:hover {
      color: #ffffff;
      background-color: #CD104D;
      box-shadow: none;
    }

    &:disabled {
      color: #a8abad;
      background: transparent;
      box-shadow: inset 0 0 0 2px #a8abad;
    }


  }

  @media screen and (max-width: 680px) {
    border-radius: 9rem;
    font-size: 1rem;
    font-weight: 600;
    line-height: 2;
  }
}
