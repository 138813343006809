$cubic: cubic-bezier(0.64, 0.09, 0.08, 1);
.assessment {
  width: auto;
  padding: 25px;
  max-width: 1300px;
  margin: 0 auto;
  border: 0;
  border-radius: 1rem;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  text-align: left;
  transition: all 0.3s ease;
  outline: none;
  color: #000;
  // color: #787881;
  // background: #ececec;
  // box-shadow: 1px 1px 9px -2px rgba(0, 0, 0, 0.53);
  @media screen and (max-width: 800px) {
    padding: 5px;
    border-radius: 0;
  }
  &--green {
    color: #49b833;
  }
  &--red {
    color: #fb1413;
  }
  &__title {
    font-size: 30px;
    font-weight: 800;
    color: #000;
    @media screen and (max-width: 480px) {
      font-size: 18px;
    }
    &--old {
      font-size: 16px;
      font-weight: 600;
      &--flex{
        display: flex;
        align-content: center;
        flex-direction: row;
        align-items: center;
      }
    }
    span {
      font-weight: 500;
    }
  }
  &__subtitle {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-size: 16px;
    // color: #929292;
  }
  &__container {
    position: relative;
  }

  &__confirm_container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
  &__confirm_overlay {
    position: absolute;
    height: 104%;
    width: 104%;
    border-radius: 10px;
    top: -2%;
    left: -2%;
    background: rgba(0, 0, 0, 0.45);
    z-index: 1;
  }

  &__confirm_modal {
    position: absolute;
    height: 50%;
    width: 80%;
    max-width: 420px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background: rgba(193, 193, 193, 0.95);
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 1px 1px 9px -2px rgba(0, 0, 0, 0.53);
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }

  &__confirm_text {
    font-size: 20px;
    width: 90%;
    text-align: center;
  }

  &__confirm_controls {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  &__confirm_control {
    padding: 15px;
    border-radius: 10px;
    background: #1c87e8;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background: darken(#1c87e8, 10%);
      transform: scale(1.2);
    }
    &:active {
      transform: scale(1.1);
    }
  }

  &__team-container {
    position: relative;
    cursor: pointer;
    margin-left: 10px;
  }
  &__team-container:hover &__team {
    opacity: 1;
    z-index: 12;
    pointer-events: all;
  }
  &__team {
    position: absolute;
    overflow: scroll;
    min-width: 320px;
    max-height: 320px;
    background: #ececec;
    right: 0;
    top: 51px;
    border-radius: 10px;
    border: 1px solid #929292;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    transition: all 0.5s ease-in-out;
    @media screen and (max-width: 480px) {
      right: -25px;
    }
  }
  &__team-person {
    display: flex;
    padding: 20px 0;
    border-bottom: 1px solid #ded9d9;
    flex: 1;
  }
  &__team-person-icon {
    width: 20%;
    & i.green {
      color: #1ca501;
      font-size: 24px;
    }
    & i.red {
      color: #ea0000;
      margin-left: 2px;
      font-size: 28px;
    }
  }
  &__team-person-name {
    width: 80%;
  }
  &__team-icon {
    position: relative;

    border-radius: 50px;
    width: 50px;
    height: 50px;
    background: #5d5d5d;
    color: #ececec;
    i {
      position: absolute;
      top: 50%;
      left: 49%;
      transform: translate(-50%, -50%);
    }
  }

  &__stats_improvements {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1 1;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  &__stats_improvements_column {
    width: 30%;
    @media screen and (max-width: 650px) {
      width: 100%;
      margin-bottom: 30px;
    }
    // padding: 0 80px ;
  }
  &__stats_improvements_title {
    font-weight: 600;
    margin-bottom: 10px;
  }
  &__stats_improvements_area {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    width: 100%;
    flex: 1 1;
    justify-content: space-between;
    padding: 10px 0;
  }
  &__stats_improvements_area_percent {
    display: flex;
    padding-left: 20px;
  }

  &__stats_overal_result {
    margin: 30px 0;
    width: 100%;
    border: 1px solid;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
  }
  &__stats_radar_container {
    overflow: hidden;
    height: 450px;
    @media screen and (max-width: 480px) {
      height: 340px;
    }
  }
  &__stats_radar {
    height: 470px;
    @media screen and (max-width: 480px) {
      height: 360px;
    }
  }
  &__stats_compare_score {
    padding-left: 15px;
    letter-spacing: 1px;
  }

  &__stats_body {
    padding: 20px 10px;
  }

  &__text {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 25px;
  }
  &__question-text {
    font-size: 24px;
    padding-bottom: 30px;
    font-weight: 400;
    text-align: center;
    transition: all 0.3s ease;
    opacity: 1;
    &--category {
      margin-top: 10px;
      font-size: 24px;
      font-weight: 700;
      padding: 0px;
    }
    @media screen and (max-width: 480px) {
      font-size: 18px;
    }
    &.faded {
      transition: all 0s ease;
      opacity: 0;
    }
    &--statements {
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      padding-bottom: 0;
      @media screen and (max-width: 640px) {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
  &__question-statement {
    width: 40%;
    text-align: left;

    &:last-child {
      text-align: right;
    }
  }
  &__controls {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-between;
    color: #1c87e8;
  }
  &__control {
    padding: 7px;
    // background: #e4e4e4;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.3s ease;
    &.no-active {
      color: #c4c4c4;
      &:hover {
        background: 0;
      }
    }
    &:hover {
      background: #c4c4c4;
    }
    &:active {
      background: #c7c7c7;
    }
    &.disabled {
      visibility: hidden;
    }
  }
  &__control &__control-icon {
    font-size: 45px;
  }
  .session-item &__group-control {
    i {
      font-size: 25px;
      padding: 0 10px;
    }
  }
  &__group {
    display: flex;
    flex: 1;
    background: #ffffff;
    flex-wrap: wrap;
    color: #676767;
    padding: 15px;
    margin: 10px 0;
    border: 1px solid #929292;
    border-radius: 10px;
    justify-content: space-between;
    max-height: 50px;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    &.active {
      max-height: 5000px;
    }
    &.active &-subgroups {
      opacity: 1;
      width: 100%;
      display: initial;
    }
    &-name {
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
    }
    &-color {
      // width: 10%;
      width: 24px;
      height: 24px;
      border-radius: 24px;
      background: #000;
    }
    &-percent {
      width: 5%;
    }
    &-control {
      cursor: pointer;
      width: 5%;
    }
    @media screen and (max-width: 480px) {
      &-name {
        width: 65%;
      }
      &-color {
        width: 24px;
        height: 24px;
      }
      &-percent {
        width: 5%;
      }
      &-control {
        width: 15%;
        i {
          padding: 0;
        }
      }
    }
    &-subtitle {
      font-size: 12px;
      color: #929292;
    }
    &-subgroups {
      width: 100%;
      opacity: 0;
      transition: all 0.5s ease;
    }
    &-subgroup-divider {
      width: 70%;
      border-bottom: 2px dashed #bbb;
      margin: 10px 0;
    }
    &-subgroup {
      display: flex;
      flex: 1;
      justify-content: right;
      align-items: center;
      padding: 10px;
      @media screen and (max-width: 480px) {
        padding: 5px;
      }
      &-name {
        width: 30%;
        color: #929292;
        text-align: right;
        padding: 10px;
        overflow: hidden;
        @media screen and (max-width: 480px) {
          padding: 0;
          text-align: left;
          font-size: 13px;
          line-height: 12px;
        }
      }
      &-bar {
        width: 62%;
        @media screen and (max-width: 480px) {
          width: 55%;
        }

        @media screen and (max-width: 480px) {
          &.labels {
            font-size: 8px;
            line-height: 12px;
          }
        }
      }
      &-bar-progress {
        width: 10%;
        padding: 7px;
        border: 1px solid #000;
        text-align: right;
        color: #fff;
        margin-top: 5px;

        background: #00bcff;
        &--light-orange {
          background: #eba92e;
        }
        &--orange {
          background: #e09427;
        }
        &--alt {
          background: #037ca5;
        }
        @media screen and (max-width: 480px) {
          padding: 3px;
        }
      }

      &-bar-progress-value {
        position: relative;
      }
      &-bar-progress-score {
        position: absolute;
        top: 0;
        right: -75px;
        width: 70px;
        text-align: left;
        @media screen and (max-width: 480px) {
          right: -62px;
        }
      }
    }
  }

  .green-tick{
    display: inline-block;
    margin-right: 10px;
  }
  .green-tick svg{
    width: 50px;
  }
  @keyframes checkmark {
    0% {
      stroke-dashoffset: 100px;
    }

    100% {
      stroke-dashoffset: 0px;
    }
  }

  @keyframes checkmark-circle {
    0% {
      stroke-dashoffset: 480px;
    }

    100% {
      stroke-dashoffset: 960px;
    }
  }

  @keyframes colored-circle {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 100;
    }
  }
  .inlinesvg .svg svg {
    display: inline;
  }

  .icon--order-success svg polyline {
    -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
    animation: checkmark 0.25s ease-in-out 0.7s backwards;
  }

  .icon--order-success svg circle {
    -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
    animation: checkmark-circle 0.6s ease-in-out backwards;
  }
  .icon--order-success svg circle#colored {
    -webkit-animation: colored-circle 0.6s ease-in-out 0.7s backwards;
    animation: colored-circle 0.6s ease-in-out 0.7s backwards;
  }

  &__stats-container{
    @keyframes statsFadeInAnimation {
      0% {
          opacity: 0;
          max-height: 550px;
      }
      100% {
          opacity: 1;
          max-height: 10000px;
      }
  }
    &--fade-in{
      opacity: 0;
      max-height: 550px;
      animation: 3s statsFadeInAnimation ease .5s;
      // animation-delay: 1s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }
}
