@keyframes checkAnimate {
  0% {
    stroke-dashoffset: -80px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}

.journey {
  width: 100%;
  $unit: 2rem;
  $bottom-margin-multiplier: 5;
  $bottom-margin: $bottom-margin-multiplier + rem;
  $height: 8rem;
  $unit_half: $unit/2;


  @keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
  }
  & &__linear-background {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee52 8%, #dddddd52 18%, #eeeeee52 33%);
    background-color: rgba(255,255,255,.3);
    border-color: rgba(255,255,255,.3);
    background-size: 1000px 104px;
    pointer-events: none;
  }

  ol {
    margin: 0;
    list-style: none;
    padding: 0;
    box-sizing: content-box;
  }
  h1 {
    text-align: center;
  }
  p {
    line-height: 1.3;
  }
  .task {
    position: relative;
    display: flex;
    flex-direction: row;
    &__top-content{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    &__content-container{
      display: flex;
      flex-direction: column;
      width: 80%;
      padding-left: 8px;
    }
    &__checkmark {
      align-self: start;
      width: 18%;
      .checkmark-check {
        stroke-dasharray: 80px 80px;
        stroke-dashoffset: -80px;
      }
    }
    &__title {
      flex: 1;
      font-weight: 600;
      font-size: 18px;
      color: #1d87e8;
      min-height: 65%;
    }
    &__description {
      flex: 1;
      margin: 0;
      height: 30%;
      font-weight: 500;
      text-align: center;
      font-size: 16px;
      color: #757575;
    }
    &__controls {
      position: absolute;
      bottom: 10px;
      left: 0;
      display: flex;
      width: 100%;
      height: 10%;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: space-between;
      color: #757575;
      font-size: 14px;
      white-space: nowrap;
      i{
        margin-right: 5px;
      }
      &-dates{
        text-align: left;
        margin-left: 25px;
      }
      &-days{
        text-align: right;
        margin-right: 25px;
      }

      & div,
      button {
        flex: 1;
      }

      .task__help_text {
        display: none;
        opacity: 0;
        font-size: 10px;
        font-weight: 600;
        color: #757575;
        transition: all 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
        
        @media (max-width: 1450px){
          font-size: 11px;
          line-height: 11px;
        }

        @media (max-width: 1150px){
          font-size: 12px;
          line-height: 12px;
        }
        @media (max-width: 550px){
          font-size: 14px;
          line-height: 14px;
        }
        @media (max-width: 350px){
          font-size: 12px;
          line-height: 11px;
        }
      }
    }
  }

  .box_button {
    $blocks_color: #0d66bf;
    width: 200px;
    padding: 8px;
    font-size: 15px;
    color: fade-out(#fff, 0.1);
    background: $blocks_color;
    border: none;
    outline: 0;
    margin-left: 10px;
    cursor: pointer;
    transition: all 0.4s ease-out;
    display: flex;
    align-items: center;
    justify-content: center;
    &:disabled {
      background: rgb(140, 140, 140);
    }
    &:disabled:hover {
      background: darken(rgb(140, 140, 140), 15%);
    }
    &:hover {
      background: lighten($blocks_color, 5%);
    }
    &:active {
      background: lighten($blocks_color, 15%);
    }
  }

  li {
    box-sizing: content-box;
    position: relative;
    display: block;
    background-color: rgba(255, 255, 255, 1);
    border-color: rgba(255, 255, 255, 1);
    padding: 1rem;
    margin: 2rem 0;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    margin-bottom: calc(#{$bottom-margin} - 1rem);
    cursor: pointer;
    &.inactive {
      background-color: #c7d8e8;
      border-color: #c7d8e8;
    }
    &.done{
      .checkmark-check {
        animation: 1s checkAnimate 1s forwards
          cubic-bezier(0.895, 0.03, 0.685, 0.22);
      }
    }

    &.completed {
      // background-color: rgb(192, 228, 208);
      // border-color:rgb(192, 228, 208);
      .task__description {
        color: #0d66bf;
        font-size: 50px;
        font-weight: 600;
      }
    }
    &:hover {
      background-color: rgba(255, 255, 255, 1);
      border-color: rgba(255, 255, 255, 1);
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
      &::before {
        //   box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        // 0 10px 10px rgba(0, 0, 0, 0.22);
      }

      .task__help_text {
        opacity: 1;
      }
    }
  }
  li::before {
    content: "";
    background-color: inherit;
    position: absolute;
    display: block;
    width: $unit;
    height: $unit;
    top: 100%;
    left: calc(50% - (#{$unit}/ 2));
  }
  li::after {
    content: "";
    position: absolute;
    display: block;
    top: calc(100% + #{$unit});
    left: calc(50% - (#{$unit}));
    border: $unit solid transparent;
    border-top-color: inherit;
  }
  li:last-child::before,
  li:last-child::after {
    content: none;
  }
  @media (max-width: 550px){
    li {
      min-height: 100px;
    }
  }
  
  @media (min-width: 550px) and (max-width: 1199px) {
    li {
      margin: 1rem;
      width: calc(50% - 5rem);
      float: left;
      height: $height;
      margin-bottom: calc(#{$bottom-margin} - #{$unit});
      margin-right: #{$unit};
    }
    li:nth-child(4n + 3),
    li:nth-child(4n + 4) {
      float: right;
    }
    li:nth-child(4n + 1)::before {
      top: calc(#{$height}/ 2);
      left: 100%;
    }
    li:nth-child(4n + 1)::after {
      top: calc(#{$height}/ 2.7);
      left: calc(100% + 1rem);
      border: #{$unit} solid transparent;
      border-left-color: inherit;
    }
    li:nth-child(4n + 3)::before {
      top: calc(#{$height}/ 2);
      left: -#{$unit};
    }
    li:nth-child(4n + 3)::after {
      top: calc(#{$height}/ 2.7);
      left: -5rem;
      border: $unit solid transparent;
      border-right-color: inherit;
    }
  }
  @media (min-width: 1200px) and (max-width: 1300px) {
    li {
      margin: 1rem;
      width: calc(33.33% - 5rem);
      float: left;
      height: $height;
      margin-bottom: calc(#{$bottom-margin} - #{$unit});
      margin-right: #{$unit};
    }
    li:nth-child(6n + 4),
    li:nth-child(6n + 5),
    li:nth-child(6n + 6) {
      float: right;
    }
    li:nth-child(6n + 1)::before,
    li:nth-child(6n + 2)::before {
      top: calc(#{$height}/ 2);
      left: 100%;
    }
    li:nth-child(6n + 1)::after,
    li:nth-child(6n + 2)::after {
      top: calc(#{$height}/ 2.7);
      left: calc(100% + 1rem);
      border: $unit solid transparent;
      border-left-color: inherit;
    }
    li:nth-child(6n + 4)::before,
    li:nth-child(6n + 5)::before {
      top: calc(#{$height}/ 2);
      left: calc(-1 * #{$unit});
    }
    li:nth-child(6n + 4)::after,
    li:nth-child(6n + 5)::after {
      top: calc(#{$height}/ 2.7);
      left: -5rem;
      border: $unit solid transparent;
      border-right-color: inherit;
    }
  }
  @media (min-width: 1300px) {
    ol {
      max-width: 1600px;
      margin: 0 auto;
    }
    li {
      margin: 1rem;
      width: calc(25% - (4rem + #{$unit}));
      float: left;
      height: $height;
      margin-bottom: $bottom-margin;
      margin-right: calc(#{$unit} + 1rem);
    }

    li:nth-child(4n + 4)::before {
      height: calc((#{$unit} + #{$unit}));
    }
    li:nth-child(4n + 4)::after {
      top: calc(100% + (#{$unit} + #{$unit}));
    }

    li:nth-child(8n + 5),
    li:nth-child(8n + 6),
    li:nth-child(8n + 7),
    li:nth-child(8n + 8) {
      float: right;
    }
    li:nth-child(8n + 1)::before,
    li:nth-child(8n + 2)::before,
    li:nth-child(8n + 3)::before {
      top: calc(#{$height}/ 2);
      left: 100%;
    }
    li:nth-child(8n + 1)::after,
    li:nth-child(8n + 2)::after,
    li:nth-child(8n + 3)::after {
      top: calc(#{$height}/ 2.7);
      left: calc(100% + (#{$unit}));
      border: $unit solid transparent;
      border-left-color: inherit;
    }
    li:nth-child(8n + 5)::before,
    li:nth-child(8n + 6)::before,
    li:nth-child(8n + 7)::before {
      top: calc(#{$height}/ 2);
      left: -#{$unit};
    }
    li:nth-child(8n + 5)::after,
    li:nth-child(8n + 6)::after,
    li:nth-child(8n + 7)::after {
      top: calc(#{$height}/ 2.7);
      left: calc(-3 * #{$unit});
      border: $unit solid transparent;
      border-right-color: inherit;
    }
  }

  .journey_modal {
    background: white;
    margin-top: 50px;
    min-height: calc(100vh - 50px);
    width: 100%;
    height: 100%;
    color: #000;
    &__title {
      text-align: center;
      font-size: 30px;
      font-weight: 500;
      padding: 30px;
    }

    &__body_container {
      margin: 0 auto;
      text-align: center;
      width: 70%;
      min-width: 250px;
      max-width: 1200px;
    }
    &__body {
      text-align: left;
      color: #848383;
      font-size: 16px;
      line-height: 26px;
      color: #333;
      margin-bottom: 30px;
    }
    &__buttons_container {
      display: flex;
      height: 20%;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .box_button {
        width: 310px;
        margin-bottom: 20px;
      }
    }
  }

  .confirm_modal {
    position: fixed;
    z-index: 999999;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    border-radius: 12px;
    &__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.664);
      z-index: 998;
    }
    &__title {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 40px;
      @media (max-width: 550px) {
        font-size: 25px;
      }
    }
    &__body {
      position: absolute;
      padding: 40px 80px;
      width: 40%;
      min-width: 320px;
      background-color: #ececec;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 1px 1px 21px -2px rgba(0, 0, 0, 0.53);
      z-index: 999;
      text-align: center;
    }

    &__buttons {
      display: flex;
      height: 20%;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .box_button {
        font-size: 20px;
        width: 200px;
        margin-bottom: 20px;
      }
    }
  }
}
