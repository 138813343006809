.assessment-stats {
  width: 100%;
  background-color: rgb(236, 236, 236);
  // box-shadow: 1px 1px 21px -2px rgba(0, 0, 0, 0.53);
  padding: 20px;

  &__name{
    padding: 14px;
    font-size: 24px;
    font-weight: 600;
  }
  &__label-container{
    display: flex;
    flex: 0 1;
    justify-content: space-between;
  }
  &__label {
    font-size: 22px;
    font-weight: 500;
    padding: 7px;
  }

  .input-range__track--background {
    background-color: #9c9c9c;
  }
  .input-range {
    width: 100%;
    margin: 0 auto;
  }
  .input-range__track {
    height: 0.6rem;
  }
  .input-range__slider {
    height: 1.5rem;
    margin-top: -1rem;
    width: 1.5rem;
  }
  .input-range__label--value {
    top: -2.4rem;
  }
}
