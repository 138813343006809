$cubic: cubic-bezier(0.64, 0.09, 0.08, 1);
.reset-password-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #fff;
  @media screen and (max-width: 640px) {
    padding: 0 10px;
  }
  &__container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    margin: 0 auto;
    max-width: 600px;
    width: 100%;
    border: 0;
    border-radius: 1rem;
    font-size: 16px;
    font-weight: 700;
    // white-space: nowrap;
    text-decoration: none;
    text-align: left;
    transition: all 0.3s ease;
    outline: none;
    color: #000000;
    background: #ececec;
    box-shadow: 1px 1px 9px -2px rgba(0, 0, 0, 0.53);
  }
  
&__fields-container{
  .group{
    .form-input{
      background-color: #ececec;
    }
  }
}
&__buttons{
  text-align: center;
}
&__title{
  text-align: center;
  padding: 40px;
  font-size: 38px;
  font-weight: bold;
}
&__description{
  text-align: center;
  padding: 15px;
  font-size: 16px;
}

.collaboration-app-homepage-logo {
  max-width: 600px;
  cursor: pointer;
}

}