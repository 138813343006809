.modal-popup {
  position: fixed;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  pointer-events: all;
  .modal-popup__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.664);
    z-index: 998;
  }
  .modal-popup__container {
    position: absolute;
    width: 90vw;
    min-width: 320px;
    max-width: 1000px;
    background-color: #ececec;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 1px 1px 21px -2px rgba(0, 0, 0, 0.53);
    z-index: 999;
    text-align: center;
    
    @media screen and (max-width: 520px) {
        width: 100vw;
        .modal-popup__body{
            max-height: 100vh;
        }
      }
      .modal-popup__body{
        padding: 40px 80px;
        overflow: scroll;
        max-height: 100vh;
        @media screen and (max-width: 520px) {
          padding: 40px 5px;
        }
      }
  }
  .modal-popup__close {
    position: absolute;
    right: 22px;
    top: 22px;
    width: 32px;
    height: 32px;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
    &:before,
    &:after {
      position: absolute;
      left: 15px;
      content: " ";
      height: 33px;
      width: 2px;
      background-color: #333;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
