$cubic: cubic-bezier(0.64, 0.09, 0.08, 1);
.bookmarks-page {
  display: flex;
  flex-direction: column;
  align-items: left;
  max-width: 1500px;
  margin: 0 auto;
  color: #fff;
  @media screen and (max-width: 600px) {
    .collab-nav-buttons{
      margin-top: 70px;
    }
    
  }
  
  @media screen and (max-width: 640px) {
    padding: 0 10px;
  }
  &__toggle{
    width: 300px;
  }
  .title {
    font-weight: bold;
    font-size: 50px;
    margin-bottom: 40px;
    @media screen and (max-width: 520px) {
      font-size: 40px;
      margin-bottom: 0px;
    }
  }
  .sub-title {
    font-size: 20px;
    max-width: 600px;
    margin-bottom: 20px;
    @media screen and (max-width: 520px) {
      margin-bottom: 0px;
    }
  }
  &__empty-data {
    font-size: 40px;
    font-weight: bold;
    padding: 40px;
    text-align: center;
  }

  &__container {
      margin: 20px;
  }
}
