.notifications-stack-manager {
  &.hide {
    display: none;
  }
}
.notifications-stack {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    right: auto;
    left: 20px;
  }
  @media screen and (max-width: 440px) {
    right: auto;
    left: 10px;
  }
  //   &:not(:hover) &__badge,
  .closed &__badge {
    @media screen and (max-width: 800px) {
      transition: 0.8s all 0.7s ease-in-out;
      opacity: 1;
    }
  }
  &__badge {
    position: absolute;
    opacity: 0;
    color: #fff;
    background: #f00;
    bottom: 28px;
    left: 41px;
    width: 20px;
    height: 20px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    z-index: 99;
    border-radius: 50%;
    transition: all 0.1s ease-in-out;
  }
  &__items {
    @media screen and (max-height: 860px) {
      max-height: 100vh;
      padding-top: 30px;
      overflow-y: auto;
    }
    @media screen and (max-width: 800px) {
      max-height: 100vh;
      padding-top: 30px;
      overflow-y: auto;
    }
  }

  &__clear-all {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
  &__clear-all-button {
    cursor: pointer;
    width: 150px;
    padding: 10px 30px;
    font-size: 16px;
    display: flex;
    background: #787b7e;
    justify-content: space-between;
    color: #fff;
    border-radius: 20px;
    transition: all 0.2s ease-in-out;
    &:hover {
      background: darken(#787b7e, 20%);
    }
    &:active {
      background: darken(#787b7e, 40%);
    }
  }

  @media screen and (max-width: 800px) {
  }

  //   &:not(:hover),
  &.closed {
    .notifications-stack__clear-all-button {
      opacity: 0;
    }
    .notifications-stack__clear-all {
      pointer-events: none;
      display: none;
    }
    .notifications-stack-item-wrapper {
      @media screen and (max-width: 800px) {
        transform-origin: 25px;
        .notifications-stack-item {
          width: 50px;
          height: 30px;
          border-radius: 5px;
          padding: 5px;
          //   margin: 0;
        }
        .notifications-stack-item__body,
        .notifications-stack-item__footer,
        .notifications-stack-item__close-icon {
          opacity: 0;
        }
      }
      &:nth-last-child(2) {
        z-index: 0;
        margin-bottom: -22%;
        transform: scale(0.94);
        .notifications-stack-item {
          background-color: hsla(0, 0, 100%, 0.8);
        }

        .notifications-stack-item__body,
        .notifications-stack-item__footer,
        .notifications-stack-item__close-icon {
          opacity: 0;
        }
      }

      &:nth-last-child(3) {
        z-index: 0;
        margin-bottom: -22%;
        transform: scale(0.9);
        .notifications-stack-item {
          margin-top: 0px;
          background-color: hsla(0, 0, 100%, 0.6);
        }

        .notifications-stack-item__body,
        .notifications-stack-item__footer,
        .notifications-stack-item__close-icon {
          opacity: 0;
        }
      }

      &:nth-last-child(n + 4) {
        z-index: 0;
        margin-bottom: -30%;
        opacity: 0;
        // pointer-events: none;
        transform: scale(0.85);
        background-color: hsla(0, 0, 100%, 0.5);
      }

      @media screen and (max-width: 800px) {
        &:nth-last-child(2) {
          z-index: 0;
          margin-bottom: -60%;
        }
        &:nth-last-child(3) {
          z-index: 0;
          margin-bottom: -60%;
        }
        &:nth-last-child(n + 4) {
          z-index: 0;
          margin-bottom: -100%;
        }
      }
    }
  }
}
.notifications-stack-item-wrapper {
  transition: all 0.7s cubic-bezier(0.47, 0, 0.745, 0.715);
}
.notifications-stack-item {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 320px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  color: #787878;

  // text-align: center;
  // color:rgba(255, 255, 255, 0);
  width: 500px;
  height: 130px;
  @media only screen and (max-width: 580px) {
    width: 300px;
  }
  box-shadow: 1px 2px 11px -2px rgba(0, 0, 0, 0.53);
  transition: all 0.7s cubic-bezier(0.47, 0, 0.745, 0.715);
  //   transition: all .7s ease-in-out;
  // max-height: 0;

  margin: 10px 0;
  margin-top: 0px;
  overflow: hidden;
  &__close-icon {
    position: absolute;
    top: 5px;
    right: 10px;
    width: 35px;
    @media only screen and (max-width: 580px) {
      right: 0;
    }
    height: 35px;
    text-align: center;
    color: #c4c4c6;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    &:hover {
      color: darken(#c4c4c6, 20%);
    }
    &:active {
      color: darken(#c4c4c6, 40%);
    }
  }
  &__body {
    height: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 0.5s ease-in-out;
  }
  &__icon {
    width: 20%;
  }
  &__mention {
    cursor: pointer;
    background: #3cc4f1;
    color: #000;
  }
  &__text {
    width: 75%;
    text-overflow: ellipsis;
    max-height: 90px;
    padding-left: 10px;
    // line-height: 23px;
    overflow-y: auto;
    color: #000;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 12px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #555;
    }
    @media screen and (max-width: 580px) {
      font-size: 15px;
    }
  }
  &__icon-img {
    // height: 100%;
    border-radius: 50%;
    height: 60px;
    @media screen and (max-width: 440px) {
      height: 50px;
    }
  }
  &__footer {
    margin-top: 10px;
    height: 30%;
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: clip;
    width: 100%;
    transition: all 0.5s ease-in-out;
  }

  &.danger {
    color: #fff;
    background-color: rgb(250, 142, 142);
  }
  &.warning {
    color: #000;
    background-color: rgb(235, 226, 104);
  }
  &.normal {
    color: #000;
    background-color: rgb(212, 212, 206);
  }
  &.success {
    color: rgb(255, 255, 255);
    background-color: rgb(71, 209, 58);
  }
  &.primary {
    color: #fff;
    background-color: rgb(33, 109, 209);
  }
  &.show {
    padding: 20px;
    visibility: visible;
    opacity: 1;
    line-height: 20px;
    font-size: 20px;
    transform: translateY(0px) scaleY(1);
    // max-height: inherit;
    margin-top: 20px;
  }

  @keyframes notyslidein {
    from {
      transform: translateX(calc(100vw * 1.5)) scale(0.8);
      transform-origin: 50% 50%;
    }

    50% {
      transform: translateX(0) scale(0.8);
    }

    to {
      transform: translateX(0) scale(1);
    }
  }

  @keyframes notyslidein2 {
    from {
      transform: translateX(calc(100vw * 1.5)) scale(0.8);
      transform-origin: 50% 50%;
    }

    50% {
      transform: translateX(0) scale(0.8);
    }

    to {
      transform: translateX(0) scale(0.94);
    }
  }

  @keyframes notyslidein3 {
    from {
      transform: translateX(calc(100vw * 1.5)) scale(0.8);
      transform-origin: 50% 50%;
    }

    50% {
      transform: translateX(0) scale(0.8);
    }

    to {
      transform: translateX(0) scale(0.9);
    }
  }

  @keyframes notyslideinfromtop {
    from {
      transform: translateY(calc(100vh - 100vh * 1.5)) scale(0.5);
      transform-origin: 50% 50%;
    }

    50% {
      transform: translateY(0) scale(0.8);
    }

    to {
      transform: translateY(0) scale(1);
    }
  }

  &__transition-enter {
    transform: translateX(calc(100vw * 1.5)) scale(0.8);
    transform-origin: 50% 50%;
    max-height: 0;
    // margin: 0;
    // padding: 0;
    &-active {
      max-height: 300px;
      animation-duration: 1s;
      animation-name: notyslidein;
      animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
      //   animation-timing-function: cubic-bezier(.17,.67,.83,1.67);
    }
  }

  &__transition-exit {
    top: 0;
    left: 0;
    opacity: 1;
    transform: scale(1);
    z-index: 1;
    max-height: 300px;
    margin: 0;
    padding: 0;
    &-active {
      transform-origin: 50% 50%;
      opacity: 0;
      max-height: 0;
      margin: 0;
      padding: 0;
      transform: scale(0.6);
      transition: all 1s ease-out;
    }
  }

  &--overkill + &__transition-enter {
    opacity: 0;
    transform: translateY(calc(100vh - 100vh * 1.5)) scale(0.4);
    &-active {
      animation-name: notyslideinfromtop;
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  &--overkill + &__transition-exit,
  &--overkill + &__transition-enter + &__transition-exit {
    opacity: 0.7;
    transform: translateY(0) scale(1);
    &-active {
      opacity: 0;
      transform: translateY(calc(100vh - 100vh * 1.5)) scale(0.4);
    }
  }

  &--overkill .notifications-stack-item {
    background-color: #fff;
    // width: 500px;
    height: 50px;
    opacity: 0.6;
    cursor: default;
  }
}

.notifications-stack.closed
  .notifications-stack-item-wrapper:nth-last-child(3).notifications-stack-item__transition-enter {
  transform: translateX(calc(100vw * 1.5)) scale(0.8);
  &-active {
    // transform: translateX(calc(100vw * 1.5)) scale(0.9);
    // animation-name: none;
    animation-name: notyslidein3;
  }
}
.notifications-stack.closed
  .notifications-stack-item-wrapper:nth-last-child(2).notifications-stack-item__transition-enter {
  transform: translateX(calc(100vw * 1.5)) scale(0.8);
  &-active {
    // transform: translateX(calc(100vw * 1.5)) scale(0.94);
    animation-name: notyslidein2;
    // animation-name: none;
  }
}

.cascade {
  @for $i from 1 through 40 {
    .notifications-stack-item__transition-exit-active:nth-child(#{$i}) {
      transition: ($i * 0.5s - 0.5s) 1s ease-out;
    }
  }
}
