.sign-in{
  
    max-width: 680px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    margin: 10vh auto;
}

.title {
    font-weight: 600;
    font-size: 20px;
  }
  
  .buttons {
    display: flex;
    justify-content: center;
  }
  
  .middle_container {
    
    text-align: center;
    max-width: 800px;
    max-height: 600px;
    margin: 10% auto;
    border-radius: 25px;
    padding: 60px;
    background-color: #fff;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transition: all 1s ease-out;
    max-height: 800px;
    .group .form-input {
      background: none;
      background-color: #ffffff;
    }
    @media screen and (max-width: 520px) {
        width: 99%;
        padding: 30px;
      }
  }