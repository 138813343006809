$cubic: cubic-bezier(0.64, 0.09, 0.08, 1);
.app-main.session {
  background: #F5F5F5;
  transition: all 1s ease-out;
}
.app-session {
  background: #F5F5F5;
  transition: all 1s ease-out;
}
.session-page {
  display: flex;
  flex-direction: column;
  align-items: left;
  max-width: 1500px;
  margin: 0 auto;

  @media screen and (max-width: 800px) {
    padding: 5px 5px;
  }
  @media screen and (max-width: 640px) {
    padding: 15px 0;
  }
  .breadcrumb {
    // width: 40%;
    max-width: 800px;
    transition: transform 1s ease-in-out,opacity .5s ease;
    opacity: 1;
    // &.change{
    //   transform: translateX(3000px);
    //   opacity: 0;
    // }
  }
  .title {
    font-weight: bold;
    font-size: 50px;
    margin-bottom: 40px;
    @media screen and (max-width: 520px) {
      font-size: 40px;
      margin-bottom: 0px;
    }
  }
  .sub-title {
    font-size: 20px;
    max-width: 600px;
    margin-bottom: 20px;
    @media screen and (max-width: 520px) {
      margin-bottom: 0px;
    }
  }
  &__container {
    margin: 20px;
    
    @media screen and (max-width: 520px) {
      margin: 5px;
    }
  }
}

.chat-container {
    &.active &__overlay {
      opacity: 1;
      pointer-events: all;
    }
    &.active &__chat{
      transform: translateX(-40vw);
      @media screen and (min-width: 1300px) {
        transform: translateX(-601px);
      }
      @media screen and (max-width: 750px) {
        transform: translateX(-100vw);
      } 
    }
  &__overlay {
    
    pointer-events: none;
    transition: opacity 0.6s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    z-index: 510;
    background-color: rgba(0, 0, 0, 0.08);
    left: 0px;
    top: 0px;
    opacity: 0;
    cursor: pointer;
    position: fixed;
    height: 100%;
    width: 100%;
  }

  &__chat{
    transform: translateX(0px);
    transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1) 0s, opacity 0.6s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 12px;
    left: 100%;
    background-color: white;
    z-index: 520;
    width: 40vw;
    top: 0px;
    opacity: 1;
    position: fixed;
    height: 100%;
    box-sizing: border-box;
    overflow: auto; 
    @media screen and (min-width: 1300px) {
      width: 600px;
    } 
    @media screen and (max-width: 750px) {
      width: 100vw;
    } 
    
  }
}



  