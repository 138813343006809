.team-card-group{
    display: flex;
    flex-direction: row;
    flex: 1 1;
    flex-wrap: wrap;

    .team-card{
      width: 20%;
      margin: 20px;
      @media screen and (max-width: 1000px) {
        width: 30%;
      }
      @media screen and (max-width: 600px) {
        width: 80%;
        margin: 20px auto;
      }
    }

  }