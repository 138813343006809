.form-select {
  select {
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
  }

  .form-input-label {
    top: 10px;
  }
  .form-input-label.shrink {
    top: -20px;
  }
  & .form-input:focus ~ .form-input-label {
    top: -20px;
  }
}
