.simple-video-player{
    display:table;
    width:auto;
    position:relative;
    width:100%;
    &__video{
        width: 100%;
        border: 1px solid black;
    }
    &__playpause{
        background: url('data:image/svg+xml;utf8, <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g><g><path d="M256,0C114.833,0,0,114.844,0,256s114.833,256,256,256s256-114.844,256-256S397.167,0,256,0z M357.771,264.969 l-149.333,96c-1.75,1.135-3.771,1.698-5.771,1.698c-1.75,0-3.521-0.438-5.104-1.302C194.125,359.49,192,355.906,192,352V160 c0-3.906,2.125-7.49,5.563-9.365c3.375-1.854,7.604-1.74,10.875,0.396l149.333,96c3.042,1.958,4.896,5.344,4.896,8.969 S360.813,263.01,357.771,264.969z"/></g></g></svg>');
        background-repeat:no-repeat;
        width:50%;
        height:50%;
        pointer-events: none;
        position:absolute;
        left:0%;
        right:0%;
        top:0%;
        z-index: 1;
        bottom:0%;
        margin:auto;
        opacity: 0;
        transition: 300ms ease all;
        // background-color: rgba(0, 0, 0, 0.4);
        // border-radius: 30px;
        margin: auto;
        background-size: contain;
        background-position: 50%;
       &.active{
           opacity: 1;

       }
    }
}
