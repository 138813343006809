
.overlay-loader-overlay{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: rgba(193, 192, 192, .8);
    z-index: 9999;
  }
  
  .overlay-loader-container{
    display: inline-block;
    svg{
        width: 130px;
        height: 130px; 
    }
  }
  