.sign-up {
  display: flex;
  flex-direction: column;
  max-width: 380px;
  @media screen and (max-width: 520px) {
    margin: 5px 0;
    max-width: 300px;
  }
  .footnote-signup{
    text-align:center;
    font-weight: 600;
    font-size:11px;
    padding-top:10px;
  }

  .title {
    display: none;
    font-size: 23px;
    margin: 10px 0;
  }
  .button-sign-up{
    text-align: center;
  }
  .form-checkbox{margin-bottom: 20px; font-size: 18px;}
}

.blacklisted-modal{

  &__title{
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 50px;
  }
  &__text{
    font-size: 30px;
    margin-bottom: 50px;
    // text-align: left;
    a{
      text-decoration: underline;
      color: #7c7c7c;
      font-size: 35px;
    }

  }

}


.agreement-modal{

  &__title{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 50px;
  }
  &__text{
    font-size: 20px;
    margin-bottom: 50px;
    text-align: left;
    a{
      text-decoration: underline;
      color: #7c7c7c;
      font-size: 35px;
    }

  }

}