.team-feed{
    display: flex;
    flex-direction: row;
    flex: 1 1;
    flex-wrap: wrap;
    margin-top: 30px;
  
    &__separator{
      color: rgb(107, 107, 107);
      padding: 20px;
      font-size: 16px;
      font-weight: 600;
    }

    .team-feed-item{
      width: 100%;
      margin: 10px;
    }

  }