.collab-breadcrumb{


    &__options{
        display: flex;
        flex:1 1;
        flex-direction: row;
        align-items: center;
        justify-content: left;
    }
    &__option{
        // min-width: 100px;
        margin-left: 20px;
    }

    &__parent {
        font-size: 16px;
        a {
            display: flex;
            flex-direction: row;
            flex: 1 1;
            transition: 300ms ease all;
            &:hover{
                background: rgba(255, 255, 255, .3);
            }
        }
    }
    &__parent-icon {
       margin-right: 15px;
       font-size: 24px;
       align-self: center;
    }
    &__parent-name {
        align-self: center;
     }
    &__current{
        background: rgb(253,205,0);
        background: linear-gradient(90deg, rgba(253,205,0,1) 0%, rgba(226,150,1,1) 120%);
        text-align: center;
        border-radius: 29px;
        padding: 20px;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
    }

    &__parent,&__current {
        @media screen and (max-width: 520px) {
            font-size: 16px;
        }
    }


    @media screen and (max-width: 600px) {
        margin-bottom: 40px;
        &__options {
            flex-direction: column;
            align-items: normal;
        }
        &__option{
            margin: 0;
        }
        &__parent {
            margin: 36px 0;
            margin-left: 5px;
            max-width: 65%;
            font-size: 16px;
            overflow: hidden;
        }
    }
}