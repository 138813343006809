.avatar-modal{
    
  &__title{
    font-size: 50px;
    font-weight: 600;
    color: #000;
    
  }
  &__text{
    margin-bottom: 20px;
  }
  &__preview{
    height: 100px;
    margin-bottom: 15px;
    img{
      height: 100%;
    }
  }
  &__body{
    color: #000;
    font-size: 30px;
    margin-bottom: 50px;
    // text-align: left;
    a{
      text-decoration: underline;
      color: #7c7c7c;
      font-size: 35px;
    }

  }
}